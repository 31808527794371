import Constants from "../constants/constant";

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}T00:00:00`;
};

export const formatDateToMMDD = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}`;
};

export const calculateNextPayDates = (selectedDayOfWeek: string) => {
  const today = new Date();

  const targetDayIndex = Constants.dayOfWeekOptions.findIndex(
    (option) => option.value === selectedDayOfWeek
  );

  const nextDates = [];

  for (let i = 0; i < 2; i++) {
    const nextPayDate = new Date(today);
    let dayDiff = targetDayIndex - nextPayDate.getDay();

    if (dayDiff <= 0) {
      dayDiff += 7;
    }

    nextPayDate.setDate(today.getDate() + dayDiff + 7 * i);
    nextDates.push(formatDate(nextPayDate));
  }

  return nextDates;
};

export const formatDateMinusOneDay = (dateStr: string | number | Date) => {
  const dateObj = new Date(dateStr);

  dateObj.setUTCDate(dateObj.getUTCDate());

  const formattedDate = `${(dateObj.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dateObj
    .getUTCDate()
    .toString()
    .padStart(2, "0")}/${dateObj.getUTCFullYear()}`;

  return formattedDate;
};

export function formatDateToDDMMMYYYY(isoDateString: string | number | Date) {
  const date = new Date(isoDateString);
  const options = {
    day: "2-digit" as "2-digit",
    month: "short" as "short",
    year: "numeric" as "numeric" | "2-digit",
  };
  return date.toLocaleDateString("en-US", options);
}

const getNeutralDate = (dateVal: string | number | Date) => {
  const date = new Date(dateVal);
  const localOffset = date.getTimezoneOffset() * 60000;
  let adjusted = new Date(date.getTime() + localOffset);

  if (isDST(adjusted)) {
    const dstOffset = getStandardTimezoneOffset(date) * 60000;
    adjusted = new Date(date.getTime() + dstOffset);
  }

  return adjusted;
};

const isDST = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return (
    date.getTimezoneOffset() <
    Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
  );
};

const getStandardTimezoneOffset = (date: Date) => {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

export const formatDateTime = (timestamp: string | number | Date) => {
  const date = getNeutralDate(timestamp);

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  hours = hours % 12 || 12;

  return `${day} ${month} ${year} at ${hours}:${formattedMinutes}`;
};
