import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import IncomeSourceFields from "./IncomeSourceFields";
import {
  deleteIncomeSource,
  getIncomeSources,
  updateIncomeSource,
} from "../../services/bankService";
import { IncomeType, LoanState } from "../../types/genericType";
import { AddOutlined, StarBorderOutlined } from "@mui/icons-material";
import TextInput from "../common/TextInput";
import { formatDateMinusOneDay } from "../../utils/dateUtils";
import { formatAmount, generateFrequency } from "../../utils/utils";
import { ClearIcon } from "@mui/x-date-pickers";
import ApiSuccess from "../errorComp/ApiSuccess";
import ErrorComp from "../errorComp/ErrorComp";
import Loader from "../loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setIncomeSourceConfirmed } from "../../redux/loanSlice";
import { RootState } from "../../redux/store";

const initialIncomeSource = {
  id: null,
  monthlyIncome: 0,
  incomeType: "Employment",
  employer: "",
  employerAddress: {
    city: "",
    state: "",
    zip: "",
  },
  timeAtEmployment: "",
  workPhoneNumber: "",
  payDay: {
    payFrequency: "",
    payDay: "",
    nextPayDate: "",
    monthlyPayDayType: "",
    firstPayWeek: "",
    firstMonthlyPayDayOfWeek: "",
    firstMonthlyPayDay: "",
    secondPayWeek: "",
    secondMonthlyPayDayOfWeek: "",
    secondMonthlyPayDay: "",
    payrollType: "",
  },
  makePrimary: false,
};

type Props = {
  updateError: Function;
};

const IncomeInfo = (props: Props) => {
  const { updateError } = props;
  const [incomeSources, setIncomeSources] = useState<IncomeType[]>([]);
  const [editingSources, setEditingSources] = useState<{
    [key: number]: boolean;
  }>({});
  const [isAddingNew, setIsAddingNew] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const dispatch = useDispatch();
  const incomeSourceConfirmed = useSelector(
    (state: RootState) => state.loan.incomeSourceConfirmed
  );

  const fetchIncomeSources = async () => {
    try {
      const response = await getIncomeSources();
      setIncomeSources(response);
    } catch (error) {
      console.error("Failed to fetch income sources", error);
    }
  };

  useEffect(() => {
    fetchIncomeSources();
  }, []);

  const loanStateString = localStorage.getItem("loanState");
  if (!loanStateString) throw new Error("Loan state not found");

  const loanState: LoanState = JSON.parse(loanStateString);

  const handleSave = async (source: IncomeType, index: number | null) => {
    setLoading(true);
    updateError("");
    try {
      await updateIncomeSource(source);
      fetchIncomeSources();
      if (index !== null) {
        const updatedSources = [...incomeSources];
        updatedSources[index] = source;
        setIncomeSources(updatedSources);
        setEditingSources((prev) => ({ ...prev, [index]: false }));
      } else {
        setIncomeSources((prev) => [...prev, source]);
        setIsAddingNew(false);
      }
      setApiSuccess(true);
      localStorage.setItem(
        "loanState",
        JSON.stringify({
          ...loanState,
          loanApplication: {
            ...loanState.loanApplication,
          },
        })
      );
      dispatch(setIncomeSourceConfirmed(true));
    } catch (error) {
      setError("Unable to save income source.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditIncomeSource = (index: number) => {
    setEditingSources((prev) => ({ ...prev, [index]: true }));
  };

  const handleCancelEdit = (index: number) => {
    setEditingSources((prev) => ({ ...prev, [index]: false }));
  };

  const handleAddIncomeSource = () => {
    setIsAddingNew(true);
  };

  const handleCancelAdd = () => {
    setIsAddingNew(false);
  };

  const makePrimary = async (source: IncomeType) => {
    const updatedSource = { ...source, makePrimary: true };
    await updateIncomeSource(updatedSource);
    fetchIncomeSources();
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteIncomeSource(id);
      fetchIncomeSources();
    } catch (error) {
      setError("Unable to delete income source.");
    }
  };

  return (
    <Box mt={3} sx={{ width: "100%" }}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: "#838588" }}>
            Income Information
          </Typography>

          {!isAddingNew && incomeSources.length < 2 && (
            <Button
              variant="text"
              sx={{
                color: "#00db8f",
                textTransform: "none",
                alignItems: "center",
                mb: 2,
              }}
              onClick={handleAddIncomeSource}
            >
              <AddOutlined sx={{ fontSize: "18px", color: "#00db8f" }} />
              Add another income source
            </Button>
          )}
        </Box>
        {loading ? (
          <Loader />
        ) : (
          <>
            {apiSuccess && (
              <ApiSuccess
                message={"Saved income info"}
                onClose={() => setApiSuccess(false)}
              />
            )}
            {error && (
              <ErrorComp
                message={
                  "Something went wrong while updating income info. Please try again!!"
                }
                onClose={() => setError("")}
              />
            )}
            {incomeSources.map((source, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                {editingSources[index] ? (
                  <IncomeSourceFields
                    data={source}
                    handleSave={(updatedSource: IncomeType) =>
                      handleSave(updatedSource, index)
                    }
                    handleCancel={() => handleCancelEdit(index)}
                  />
                ) : (
                  <Grid container spacing={2} key={source.id}>
                    {!source.makePrimary && index >= 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          mt: 5,
                          width: "100%",
                          mb: -4,
                        }}
                      >
                        <Button
                          variant="text"
                          sx={{
                            color: "#00db8f",
                            textTransform: "none",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            makePrimary(source);
                          }}
                        >
                          <StarBorderOutlined
                            sx={{ fontSize: "18px", color: "#00db8f" }}
                          />
                          Make Primary
                        </Button>
                        <Button
                          variant="text"
                          sx={{
                            color: "#00db8f",
                            textTransform: "none",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            source.id && handleDelete(source.id);
                          }}
                        >
                          <ClearIcon
                            sx={{ fontSize: "18px", color: "#00db8f" }}
                          />
                          Delete
                        </Button>
                      </Box>
                    )}
                    <Grid item xs={12}>
                      <TextInput
                        label="Monthly Net Income"
                        value={formatAmount(source?.monthlyIncome)}
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Source of Income"
                        value={`${source?.incomeType} (${source?.employer})`}
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Pay Schedule"
                        value={source?.payDay?.payFrequency}
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Frequency"
                        value={generateFrequency(source?.payDay)}
                        readOnly
                      />
                    </Grid>
                    {source?.payDay?.nextPayDate && (
                      <Grid item xs={12}>
                        <TextInput
                          label="Next Pay Date"
                          value={formatDateMinusOneDay(
                            source?.payDay?.nextPayDate
                          )}
                          readOnly
                        />
                      </Grid>
                    )}
                    {index === 0 && (
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          sx={{
                            color: incomeSourceConfirmed ? "white" : "#721c24",
                            backgroundColor: incomeSourceConfirmed
                              ? "#00db8f"
                              : "#f8d7da",
                            textTransform: "none",
                            ":hover": {
                              backgroundColor: incomeSourceConfirmed
                                ? "#00db8f"
                                : "#f8d7da",
                              color: incomeSourceConfirmed
                                ? "white"
                                : "#721c24",
                            },
                          }}
                          onClick={() => handleEditIncomeSource(index)}
                        >
                          {incomeSourceConfirmed ? "Update" : "Confirm"}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Box>
            ))}
            {isAddingNew && (
              <IncomeSourceFields
                data={initialIncomeSource}
                handleCancel={handleCancelAdd}
                handleSave={(source: IncomeType) => handleSave(source, null)}
              />
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default IncomeInfo;
