import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, Link } from "@mui/material";
import "./LoanAgreement.css";
import GenericButton from "../../components/common/GenericButton";

interface LoanAgreementProps {
  agreementHtml: string;
  agreementPdf: string | null;
  onRequestAgreement: (format: "html" | "pdf") => void;
  isAdditionalAgreement: boolean;
}

declare global {
  interface Window {
    jQuery: any;
  }
}

const LoanAgreement: React.FC<LoanAgreementProps> = ({
  agreementHtml,
  agreementPdf,
  onRequestAgreement,
  isAdditionalAgreement,
}) => {
  const [formatDisplay, setFormatDisplay] = useState<"html" | "pdf">("html");
  const [pdfDownloadRequested, setPdfDownloadRequested] = useState(false);
  const pdfDownloadLinkRef = useRef<HTMLAnchorElement>(null);
  const agreementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pdfDownloadRequested && !agreementPdf) {
      onRequestAgreement("pdf");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfDownloadRequested, agreementPdf, onRequestAgreement]);

  useEffect(() => {
    if (agreementPdf && pdfDownloadRequested) {
      pdfDownloadLinkRef.current?.click();
      setPdfDownloadRequested(false);
    }
  }, [agreementPdf, pdfDownloadRequested]);

  const loadJQuery = () => {
    return new Promise<void>((resolve, reject) => {
      if (window.jQuery) {
        resolve();
      } else {
        const script = document.createElement("script");
        script.src = "https://code.jquery.com/jquery-3.6.0.min.js";
        script.onload = () => resolve();
        script.onerror = () => reject("Failed to load jQuery");
        document.body.appendChild(script);
      }
    });
  };

  useEffect(() => {
    loadJQuery();

    if (agreementHtml && agreementRef.current) {
      agreementRef.current.innerHTML = agreementHtml;

      const scriptElements = agreementRef.current.querySelectorAll("script");
      scriptElements.forEach((script, index) => {
        if (index === 0) {
          script.remove();
        }
        const newScript = document.createElement("script");
        newScript.type = "text/javascript";

        if (script.src) {
          newScript.src = script.src;
          newScript.async = script.async;
        } else {
          newScript.textContent = script.textContent;
        }
        Array.from(script.attributes).forEach((attr) =>
          newScript.setAttribute(attr.name, attr.value)
        );
        document.body.appendChild(newScript);
      });

      agreementRef.current
        .querySelectorAll(
          ".MobileModal hr, #adoptElectronicSignatureModal hr, #adoptElectronicSignatureModal br"
        )
        .forEach((el) => el.remove());
    }
  }, [agreementHtml]);

  useEffect(() => {
    const fontLink = document.createElement("link");
    fontLink.rel = "stylesheet";
    fontLink.href = "https://fonts.googleapis.com/css?family=Alex+Brush";
    document.head.appendChild(fontLink);
  }, []);

  const changeFormat = useCallback(
    (format: "html" | "pdf") => {
      setFormatDisplay(format);
      if (format === "html" && !agreementHtml) {
        onRequestAgreement("html");
        window.location.reload();
      }
      if (format === "pdf" && !agreementPdf) onRequestAgreement("pdf");
    },
    [agreementHtml, agreementPdf, onRequestAgreement]
  );

  const handleDownloadPDF = () => {
    setPdfDownloadRequested(true);
    if (!agreementPdf) onRequestAgreement("pdf");
  };

  return (
    <Box>
      <Typography
        variant="body2"
        sx={{ fontSize: "24px", textAlign: "center", fontWeight: "500", mb: 1 }}
      >
        {isAdditionalAgreement ? "Sign Loan Agreement" : "Loan Agreement"}
      </Typography>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", textAlign: "center", mb: 2, color: "#838588" }}
      >
        {isAdditionalAgreement
          ? "Read the terms and add your signature below to acknowledge the loan terms and agreement."
          : "Read the terms of your current loan."}
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "12px", color: "#838588" }}>
        Remember, you can pay off your loan at any time! There are never
        pre-payment penalties. To view the full document, please scroll down.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row-reverse" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <>
          <GenericButton
            label="Download PDF"
            onClick={handleDownloadPDF}
            additionalStyles={{ mt: 2, mb: 3 }}
          />
          <a
            ref={pdfDownloadLinkRef}
            href={`data:application/pdf;base64,${agreementPdf}`}
            download="loanAgreement.pdf"
            style={{ display: "none" }}
          >
            PDF Download Link
          </a>
        </>
        {isAdditionalAgreement && (
          <Typography
            variant="body2"
            sx={{
              fontSize: "24px",
              textAlign: "center",
              fontWeight: "bold",
              color: "#00db8f",
              fontFamily: "AvenirNext-Medium",
              mb: 1,
              ml: { xs: 0, sm: 6 },
            }}
          >
            Please complete steps 1 and 2 below
          </Typography>
        )}
      </Box>
      <Typography
        variant="body2"
        sx={{
          fontSize: "11pt",
          textAlign: "left",
          fontWeight: "500",
          fontFamily: "AvenirNext-DemiBold",
          mt: 2,
          mb: 1,
          color: "#838588",
        }}
      >
        Step 1: Loan Agreement
      </Typography>
      {formatDisplay === "html" && agreementHtml && (
        <Box
          ref={agreementRef}
          id="loan-agreement"
          sx={{ border: "1px solid #ddd", padding: "15px" }}
          className="loan-agreement pd-10 emphasized"
          dangerouslySetInnerHTML={{ __html: agreementHtml }}
        />
      )}
      {formatDisplay === "pdf" && agreementPdf && (
        <Box className="loan-agreement noscroll nopad emphasized">
          <object
            data={`data:application/pdf;base64,${agreementPdf}`}
            type="application/pdf"
            width="100%"
            height="100%"
            aria-label="Loan Agreement PDF"
          />
        </Box>
      )}
      <Box className="switch" sx={{ marginTop: 1 }}>
        {formatDisplay === "html" && agreementHtml && (
          <Link
            onClick={() => changeFormat("pdf")}
            sx={{
              cursor: "pointer",
              textDecoration: "none",
              color: "#00db8f",
            }}
          >
            View as PDF
          </Link>
        )}
        {formatDisplay === "pdf" && agreementPdf && (
          <Box>
            Having trouble viewing this?{" "}
            <Link
              onClick={() => changeFormat("html")}
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                color: "#00db8f",
              }}
            >
              View as HTML
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LoanAgreement;
