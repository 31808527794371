import React, { useState } from "react";
import {
  Button,
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Reward } from "../../types/genericType";
import "./RedeemPage.css";

interface SummaryItemProps {
  value: string;
  item: any;
  getSelectedReward: (sku: string) => Reward | undefined;
  onRemove: (sku: string) => void;
  updateCart: Function;
}

const SummaryItem: React.FC<SummaryItemProps> = ({
  value,
  item,
  getSelectedReward,
  onRemove,
  updateCart,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    value || getSelectedReward(item.sku)?.options[0]?.sku || ""
  );

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    const prevSku = item.sku;
    setSelectedOption(event.target.value as string);
    updateCart(prevSku, event.target.value);
  };

  return (
    <div>
      <img
        src={getSelectedReward(item.sku)?.imageUrl}
        alt={item.description}
        className="reward-image"
      />
      <Typography sx={{ color: "#00db8f", margin: "10px 0" }}>
        Terms & Conditions
      </Typography>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "18px",
          marginTop: "20px",
          color: "#838588",
          textTransform: "uppercase",
        }}
      >
        {item.description}
      </Typography>
      <Select
        value={selectedOption}
        onChange={handleOptionChange}
        displayEmpty
        sx={{ width: "100%", height: "40px" }}
      >
        <MenuItem value="">
          <em>Select an Option</em>
        </MenuItem>
        {getSelectedReward(item.sku)?.options?.map((option) => (
          <MenuItem value={option.sku} key={option.sku}>
            {option.points} PTS - ${option.amount}
          </MenuItem>
        ))}
      </Select>
      <Button
        sx={{
          color: "#004d40",
          textTransform: "none",
          fontSize: "14px",
          margin: "10px 0px",
        }}
        onClick={() => onRemove(item.sku)}
      >
        Remove From Cart
      </Button>
    </div>
  );
};

export default SummaryItem;
