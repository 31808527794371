import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBankAccounts, getBankCards } from "../services/bankService";
import { BankAccountType, BankCardType } from "../types/genericType";

export const fetchBankAccounts = createAsyncThunk(
  "bank/fetchBankAccounts",
  async (_, { rejectWithValue }) => {
    try {
      const accounts = await getBankAccounts();
      return accounts;
    } catch (error) {
      return rejectWithValue("Failed to fetch bank accounts");
    }
  }
);

export const fetchBankCards = createAsyncThunk(
  "bank/fetchBankCards",
  async (_, { rejectWithValue }) => {
    try {
      const cards = await getBankCards();
      return cards;
    } catch (error) {
      return rejectWithValue("Failed to fetch debit cards");
    }
  }
);

interface BankState {
  bankAccounts: BankAccountType[];
  bankCards: BankCardType[];
  loading: boolean;
  error: string;
}

const initialState: BankState = {
  bankAccounts: [],
  bankCards: [],
  loading: false,
  error: "",
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBankAccounts.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchBankAccounts.fulfilled, (state, action) => {
        state.bankAccounts = action.payload;
        state.loading = false;
      })
      .addCase(fetchBankAccounts.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })
      .addCase(fetchBankCards.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(fetchBankCards.fulfilled, (state, action) => {
        state.bankCards = action.payload;
        state.loading = false;
      })
      .addCase(fetchBankCards.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export default bankSlice.reducer;
