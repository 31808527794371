import React, { useEffect, useRef } from "react";
import BankAccount from "../../components/bankAccount/BankAccount";
import BankCard from "../../components/bankCard/BankCard";
import BasicProfileInfo from "../../components/basicProfileInfo/BasicProfileInfo";
import PasswordInfo from "../../components/passwordInfo/PasswordInfo";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import MilitaryInfo from "../../components/militaryInfo/MilitaryInfo";
import IncomeInfo from "../../components/incomeInfo/IncomeInfo";
import ConsentModal from "../Modals/ConsentModal";
import {
  checkEligibility,
  submitReloan,
} from "../../services/loanPortalService";
import {
  CheckEligibilityResponse,
  LoanState,
  ReloanBodyType,
} from "../../types/genericType";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import ErrorComp from "../../components/errorComp/ErrorComp";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface Step2Props {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const Step2 = ({ handleNextStep, handlePreviousStep }: Step2Props) => {
  const [consent, setConsent] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [apiError, setApiError] = React.useState<string>("");
  const navigate = useNavigate();

  const errorFieldRef = useRef<HTMLDivElement | null>(null);
  const apiErrorFieldRef = useRef<HTMLDivElement | null>(null);

  const loanStateString = localStorage.getItem("loanState");
  if (!loanStateString) throw new Error("Loan state not found");

  const loanState: LoanState = JSON.parse(loanStateString);

  const incomeSourceConfirmed = useSelector(
    (state: RootState) => state.loan.incomeSourceConfirmed
  );

  const checkValidations = async () => {
    setApiError("");
    if (incomeSourceConfirmed) {
      try {
        setLoading(true);
        const response: CheckEligibilityResponse = await checkEligibility({
          validations: [
            "address",
            "age",
            "militaryStatus",
            "income",
            "timeEmployed",
          ],
          loanRequestType: "Reloan",
        });

        if (response?.allow) {
          const customerString =
            sessionStorage.getItem("customerDetails") || "{}";
          const customer = JSON.parse(customerString);

          const data: ReloanBodyType = {
            loanTypeId: 5,
            loanType: loanState?.loanApplication?.loanType || "Unknown",
            loanAmount: loanState?.loanApplication?.loanAmount || "0",
            state: customer?.address?.state || "Unknown",
            zip: customer?.address?.zip || "00000",
            loanRequestType:
              loanState?.loanApplication?.loanRequestType || "Reloan",
            repaymentMethod:
              loanState?.loanApplication?.repaymentMethod || "Ach",
          };

          const res = await submitReloan(data);
          if (typeof res === "string") {
            setLoading(false);
            setApiError(res);
          } else {
            setLoading(false);
            handleNextStep();
          }
        } else {
          navigate("/sorry/not-eligible", {
            state: {
              error: { message: response.message, details: response.rule },
            },
          });
        }
      } catch (error) {
        setLoading(false);
        setApiError("An unexpected error occurred. Please try again.");
      }
    } else {
      setError(
        "You must Update/Confirm your income before submitting your application"
      );
    }
  };

  useEffect(() => {
    if (errorFieldRef.current) {
      const elementTop = errorFieldRef.current.getBoundingClientRect().top;
      const offsetPosition = elementTop + window.pageYOffset - 300;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    if (apiErrorFieldRef.current) {
      const elementTop = apiErrorFieldRef.current.getBoundingClientRect().top;
      const offsetPosition = elementTop + window.pageYOffset - 300;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [error, apiError]);

  return (
    <Container>
      {apiError && (
        <Container ref={apiErrorFieldRef}>
          <ErrorComp message={apiError} onClose={() => setApiError("")} />
        </Container>
      )}
      <Grid container spacing={2}>
        <BasicProfileInfo />
        <PasswordInfo />
        <MilitaryInfo />
        {error && (
          <Container ref={errorFieldRef}>
            <ErrorComp message={error} onClose={() => setError("")} />
          </Container>
        )}
        <IncomeInfo updateError={setError} />
        <BankAccount />
        <BankCard />
        <Grid item xs={12}>
          <FormControlLabel
            sx={{ display: "flex", alignItems: "start" }}
            control={
              <Checkbox
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
                name="checked"
                color="primary"
                sx={{ padding: "0px 9px", color: "#00db8f" }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "#838588" }}
              >
                By submitting your application, you expressly consent to be
                contacted by Lift Credit or our agents by calls and text
                messages for all purposes arising out of or relating to your
                application or use of our services at the telephone number(s)
                you provide and represent and warrant that you have read, or had
                the opportunity to read, and agree to our electronic
                communications agreement specified{" "}
                <Link
                  href="#"
                  underline="always"
                  sx={{
                    cursor: "pointer",
                    color: "#00db8f",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={() => setOpen(true)}
                >
                  here
                </Link>
                .
              </Typography>
            }
          />
        </Grid>
        <ConsentModal open={open} handleClose={() => setOpen(false)} />
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={checkValidations}
          title="CONTINUE"
          type="contained"
          disabled={loading}
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          variant="text"
          onClick={handlePreviousStep}
          sx={{
            color: "#00db8f",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Go Back
        </Button>
      </Box>
      <Backdrop
        open={loading}
        sx={{ backgroundColor: "white", opacity: 0.7, zIndex: 9999 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "800px",
          }}
        >
          <Loader message="Thank you for requesting a new loan! Our system is going through your application. This process could take up to 2 minutes. If the screen doesn't update after that time, please try refreshing your browser. Thanks for waiting!" />
        </Box>
      </Backdrop>
    </Container>
  );
};

export default Step2;
