import React from "react";
import { BankAccountType } from "../../types/genericType";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { Star, StarOutlineOutlined } from "@mui/icons-material";

type Props = {
  bankAccount: BankAccountType;
  selectedBankActId: number | "new";
  setSelectedBankActId: (id: number) => void;
  hideRadio: boolean;
  onPaymentPage?: boolean;
  makePrimary?: boolean;
  setMakePrimary?: Function;
};

const PaymentBankAccount = (props: Props) => {
  const {
    bankAccount,
    selectedBankActId,
    setSelectedBankActId,
    hideRadio,
    onPaymentPage,
    makePrimary,
    setMakePrimary,
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        padding: hideRadio ? "40px" : "20px",
        alignItems: "start",
        backgroundColor:
          selectedBankActId === bankAccount.id && !hideRadio
            ? "#f4f4f4"
            : "white",
      }}
    >
      {!hideRadio && (
        <Radio
          checked={selectedBankActId === bankAccount.id}
          sx={{
            padding: 0,
            color: "#00db8f",
            "&.Mui-checked": {
              color: "#00db8f",
            },
            mr: 2,
          }}
          onChange={() => setSelectedBankActId(bankAccount.id ?? 0)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          justifyContent: "start",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#838588",
            fontWeight: "500",
          }}
        >
          {bankAccount.bankName?.toUpperCase()}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#838588",
            fontWeight: "500",
          }}
        >
          {bankAccount.bankAccountNumber}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#838588",
            fontWeight: "500",
            fontStyle: "italic",
          }}
        >
          {bankAccount.bankAccountType}
        </Typography>
        {bankAccount.isPrimaryBankAccount && (
          <Typography
            variant="body2"
            sx={{
              color: "#838588",
              fontWeight: "500",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Star sx={{ fontSize: "18px", color: "#838588", mr: 1 }} />
            Primary Bank Account
          </Typography>
        )}
        {onPaymentPage &&
          !bankAccount.isPrimaryBankAccount &&
          selectedBankActId === bankAccount.id && (
            <FormControlLabel
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              control={
                <Checkbox
                  checked={makePrimary}
                  onChange={(e) => {
                    setMakePrimary && setMakePrimary(e.target.checked);
                  }}
                  name=""
                  color="primary"
                  sx={{
                    padding: "0px 2px",
                    borderRadius: "5px",
                    color: "#00db8f",
                    "&.Mui-checked": {
                      color: "#00db8f",
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <StarOutlineOutlined
                    sx={{ fontSize: "18px", color: "#838588", mr: "2px" }}
                  />
                  Make Primary
                </Typography>
              }
            />
          )}
      </Box>
    </Box>
  );
};

export default PaymentBankAccount;
