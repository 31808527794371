import React from "react";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import { formatDateToDDMMMYYYY } from "../../utils/dateUtils";
import { formatAmount } from "../../utils/utils";

type Props = {
  currentLoanStatus: any;
};

const DeniedLoan = (props: Props) => {
  const { currentLoanStatus } = props;

  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
      <Box sx={{ textAlign: "left", marginBottom: "2rem" }}>
        <Typography variant="h5" sx={{ color: "#00db8f", fontWeight: "bold" }}>
          Loan Status: {currentLoanStatus?.status}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "2rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Loan Amount
            </Typography>
            <Typography variant="h6" sx={{ color: "#333" }}>
              {formatAmount(currentLoanStatus?.originalAmount)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "2rem",
                fontWeight: "bold",
              }}
            >
              Application Date :{" "}
              {formatDateToDDMMMYYYY(currentLoanStatus?.appliedDate)}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "2rem",
                fontWeight: "bold",
              }}
            >
              Loan # : {currentLoanStatus?.id}
            </Typography>
          </Box>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
        />
        <Grid item xs={12} sm={5}>
          <Box sx={{ padding: "2rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Reason for Denial
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "400",
              }}
            >
              Loan applications may be rejected for one or more of the following
              reasons:
            </Typography>
            <ul style={{ color: "#838588", paddingLeft: "1rem" }}>
              <li>Required docs not provided</li>
              <li>Insufficient employment history</li>
              <li>Insufficient income</li>
              <li>Application canceled by customer</li>
              <li>Open bankruptcy or too many recent cases</li>
              <li>Did not pass Consumer Disclosure</li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DeniedLoan;
