import { AxiosResponse } from "axios";
import axiosInstance from "../core/axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import {
  ApiResponse,
  BankCardType,
  BankAccountType,
  IncomeType,
} from "../types/genericType";

export const getBankInfo = async (routingNumber: String): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axiosInstance.get(
      `${apiEndPoints.bankName}/${routingNumber}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const validateBankInfo = async (bankData: {
  accountNumber: string;
  bankName: string;
  routingNumber: string;
}): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.bankInfo}`,
      bankData
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getBankCards = async () => {
  try {
    const response: AxiosResponse<ApiResponse<BankCardType>> =
      await axiosInstance.get(apiEndPoints.bankCards);
    return response?.data?.resources;
  } catch (err) {
    throw err;
  }
};

export const saveBankCard = async (cardData: BankCardType) => {
  try {
    await axiosInstance.post(apiEndPoints.bankCards, cardData);
  } catch (err) {
    throw err;
  }
};

export const makePrimaryBankCard = async (cardId: string) => {
  try {
    await axiosInstance.put(`${apiEndPoints.bankCards}/${cardId}`, {
      id: cardId,
      isPrimaryBankCard: true,
    });
  } catch (err) {
    throw err;
  }
};

export const getBankAccounts = async () => {
  try {
    const response: AxiosResponse<ApiResponse<BankAccountType>> =
      await axiosInstance.get(apiEndPoints.bankAccounts);
    return response?.data?.resources;
  } catch (err) {
    throw err;
  }
};

export const saveBankAccount = async (bankData: BankAccountType | null) => {
  try {
    await axiosInstance.post(apiEndPoints.bankAccounts, bankData);
  } catch (err) {
    throw err;
  }
};

export const makePrimaryBankAccount = async (accountId: string) => {
  try {
    await axiosInstance.put(`${apiEndPoints.bankAccounts}/${accountId}`, {
      id: accountId,
      isPrimaryBankAccount: true,
    });
  } catch (err) {
    throw err;
  }
};

export const getIncomeSources = async () => {
  try {
    const response: AxiosResponse<ApiResponse<IncomeType>> =
      await axiosInstance.get(apiEndPoints.incomeSources);
    return response?.data?.resources;
  } catch (err) {
    throw err;
  }
};

export const updateIncomeSource = async (incomeSource: IncomeType) => {
  try {
    await axiosInstance.post(apiEndPoints.incomeSources, incomeSource);
  } catch (err) {
    throw err;
  }
};

export const deleteIncomeSource = async (id: number) => {
  try {
    await axiosInstance.delete(`${apiEndPoints.incomeSources}/${id}`);
  } catch (err) {
    throw err;
  }
};

export const makePayment = async (
  paymentAmount: string,
  paymentType: "DebitCard" | "Ach",
  paymentBankCard?: BankCardType | null,
  paymentBankAccount?: BankAccountType | null
) => {
  try {
    const payload = {
      paymentAmount,
      paymentType,
      paymentBankCard: paymentType === "DebitCard" ? paymentBankCard : null,
      paymentBankAccount: paymentType === "Ach" ? paymentBankAccount : null,
    };

    const response: AxiosResponse<ApiResponse<any>> = await axiosInstance.post(
      apiEndPoints.makePayment,
      payload
    );

    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const checkDuplicatePayment = async (
  paymentAmount: string,
  paymentBankCard?: BankCardType | null
) => {
  try {
    const payload = {
      paymentAmount,
      paymentType: "DebitCard",
      paymentBankCard: paymentBankCard,
      paymentBankAccount: null,
    };

    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.makePayment}/checkduplicatepayment`,
      payload
    );

    return response?.data;
  } catch (err) {
    throw err;
  }
};
