import React, { useState, useEffect } from "react";
import { BankAccountType, BankCardType } from "../../types/genericType";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import PaymentBankAccount from "./PaymentBankAccount";
import PaymentBankCard from "./PaymentBankCard";
import Dropdown from "../../components/common/Dropdown";
import Constants from "../../constants/constant";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SampleCheckModal from "../../pages/Modals/SampleCheckModal";
import { getBankInfo } from "../../services/bankService";
import AddressForm from "../../components/addressForm/AddressForm";

type Props = {
  bankAccount: boolean;
  debitCard: boolean;
  bankAccounts: BankAccountType[];
  bankCards: BankCardType[];
  updateSelectedBankAccount: (account: BankAccountType) => void;
  updateSelectedBankCard: (card: BankCardType) => void;
  errors: any;
  showExistingBankDetails: boolean;
  fundingChecklistState: any;
  fundingCheck: string;
};

const PaymentDetails = (props: Props) => {
  const {
    bankAccount,
    debitCard,
    bankAccounts,
    bankCards,
    updateSelectedBankAccount,
    updateSelectedBankCard,
    errors,
    showExistingBankDetails,
    fundingChecklistState,
    fundingCheck,
  } = props;
  const {
    isEditable,
    savedLoanFundingMethodBankOrCardLabel,
    savedDefaultFundingMethodBankOrCardLabel,
  } = fundingChecklistState;
  const [selectedBankActId, setSelectedBankActId] = useState<number>(
    bankAccounts[0]?.id ?? 0
  );
  const [selectedBankCardId, setSelectedBankCardId] = useState(
    bankCards[0]?.id ?? 0
  );
  const [cards, setCards] = useState<BankCardType[]>(bankCards);
  const [accounts, setAccounts] = useState<BankAccountType[]>(bankAccounts);
  const [open, setOpen] = useState(false);
  const [readOnlyAccount, setReadOnlyAccount] = useState(false);
  const [readOnlyCard, setReadOnlyCard] = useState(false);
  const [newAccount, setNewAccount] = useState({
    bankName: "",
    bankAccountNumber: "",
    bankAccountType: "",
    bankRoutingNumber: "",
  });

  const [newCard, setNewCard] = useState({
    nameOnCard: "",
    cardNumber: "",
    billingAddress: {
      addressLine1: "",
      city: "",
      state: "",
      zip: "",
    },
    expiration: "",
    securityCode: "",
    billingAddressSameAsPrimary: true,
  });

  useEffect(() => {
    if (bankAccount) {
      updateSelectedBankAccount(bankAccounts[0]);
    } else {
      updateSelectedBankCard(bankCards[0]);
    }

    if (isEditable) {
      if (fundingCheck === "loanFundingMethod") {
        if (savedLoanFundingMethodBankOrCardLabel === "Bank Account") {
          setCards([bankCards[0]]);
          setReadOnlyCard(true);
        } else {
          setAccounts([bankAccounts[0]]);
          setReadOnlyAccount(true);
        }
      } else {
        if (savedDefaultFundingMethodBankOrCardLabel === "Bank Account") {
          setCards([bankCards[0]]);
          setReadOnlyCard(true);
        } else {
          setAccounts([bankAccounts[0]]);
          setReadOnlyAccount(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedBankActId === 0) {
      updateSelectedBankAccount({
        id: null,
        bankName: newAccount.bankName,
        bankAccountNumber: newAccount.bankAccountNumber,
        bankAccountType: newAccount.bankAccountType,
        bankRoutingNumber: newAccount.bankRoutingNumber,
      });
    } else {
      updateSelectedBankAccount(
        bankAccounts.find((act) => act.id === selectedBankActId) ??
          bankAccounts[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBankActId, newAccount]);

  const handleNewAccountChange = async (field: string, value: string) => {
    setNewAccount({ ...newAccount, [field]: value });

    if (field === "bankRoutingNumber" && value.length === 9) {
      try {
        const bankName = await getBankInfo(value);
        setNewAccount((prevFormData) => ({
          ...prevFormData,
          bankName,
        }));
      } catch (error) {
        console.error("Failed to fetch bank info:", error);
      }
    }
  };

  const handleNewCardChange = (field: string, value: string) => {
    let formattedValue = value;

    if (field === "cardNumber") {
      formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{4})(?=\d)/g, "$1-")
        .substring(0, 19);
    } else if (field === "expiration") {
      formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{2})(?=\d)/g, "$1/")
        .substring(0, 5);
    } else if (field === "securityCode") {
      formattedValue = value.replace(/\D/g, "").substring(0, 4);
    }
    setNewCard({ ...newCard, [field]: formattedValue });
  };

  const handleAddressChange = ({
    id,
    value,
  }: {
    id: string;
    value: string;
  }) => {
    setNewCard((prev) => ({
      ...prev,
      billingAddress: {
        ...prev.billingAddress,
        [id]: value,
      },
    }));
  };

  useEffect(() => {
    if (selectedBankCardId === 0) {
      updateSelectedBankCard({
        id: null,
        nameOnCard: newCard.nameOnCard,
        cardNumber: newCard.cardNumber,
        expiration: newCard.expiration,
        securityCode: newCard.securityCode,
        billingAddress: newCard.billingAddressSameAsPrimary
          ? null
          : newCard.billingAddress,
        billingAddressSameAsPrimary: newCard.billingAddressSameAsPrimary,
      });
    } else {
      updateSelectedBankCard(
        bankCards.find((card) => card.id === selectedBankCardId) ?? bankCards[0]
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBankCardId, newCard]);

  return (
    <Box sx={{ mt: 2, mb: 4 }}>
      {bankAccount &&
        accounts &&
        accounts.map((account) => (
          <PaymentBankAccount
            key={account.id}
            bankAccount={account}
            selectedBankActId={selectedBankActId}
            setSelectedBankActId={setSelectedBankActId}
            hideRadio={isEditable ? readOnlyAccount : showExistingBankDetails}
          />
        ))}
      {!isEditable && !showExistingBankDetails && bankAccount && (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            padding: "20px",
            backgroundColor: selectedBankActId === 0 ? "#f4f4f4" : "white",
          }}
        >
          <Radio
            checked={selectedBankActId === 0}
            sx={{
              padding: 0,
              color: "#00db8f",
              "&.Mui-checked": {
                color: "#00db8f",
              },
              mr: 2,
            }}
            onChange={() => setSelectedBankActId(0)}
          />
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                fontWeight: "500",
              }}
            >
              Pay with new bank account
            </Typography>
            {selectedBankActId === 0 && (
              <Grid container spacing={2} sx={{ mt: 1, pr: 2 }}>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      paddingLeft: "8px",
                      marginBottom: "5px",
                      color: "#838588",
                    }}
                  >
                    Routing Number
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={newAccount.bankRoutingNumber}
                    onChange={(e) =>
                      handleNewAccountChange(
                        "bankRoutingNumber",
                        e.target.value
                      )
                    }
                    fullWidth
                    size="small"
                    placeholder="Enter Routing Number"
                    error={!!errors.bankAccount.bankRoutingNumber}
                    helperText={errors.bankAccount.bankRoutingNumber}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setOpen(true)}>
                            <HelpOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      paddingLeft: "8px",
                      marginBottom: "5px",
                      color: "#838588",
                    }}
                  >
                    Account Number
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={newAccount.bankAccountNumber}
                    onChange={(e) =>
                      handleNewAccountChange(
                        "bankAccountNumber",
                        e.target.value
                      )
                    }
                    fullWidth
                    size="small"
                    placeholder="Enter Account Number"
                    error={!!errors.bankAccount.bankAccountNumber}
                    helperText={errors.bankAccount.bankAccountNumber}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setOpen(true)}>
                            <HelpOutlineIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      paddingLeft: "8px",
                      marginBottom: "5px",
                      color: "#838588",
                    }}
                  >
                    Bank Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={newAccount.bankName}
                    onChange={(e) =>
                      handleNewAccountChange("bankName", e.target.value)
                    }
                    fullWidth
                    size="small"
                    placeholder="Enter Bank Name"
                    error={!!errors.bankAccount.bankName}
                    helperText={errors.bankAccount.bankName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    id="bankAccountType"
                    label="Account Type"
                    value={newAccount.bankAccountType || ""}
                    options={Constants.bankAccountTypeOptions.map((option) => ({
                      value: option.value,
                      label: option.name,
                    }))}
                    onChange={(e) =>
                      handleNewAccountChange("bankAccountType", e)
                    }
                    required={true}
                    error={errors.bankAccount.bankAccountType}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      )}
      {debitCard &&
        cards &&
        cards.map((card) => (
          <PaymentBankCard
            key={card.id}
            bankCard={card}
            selectedBankCardId={selectedBankCardId}
            setSelectedBankCardId={setSelectedBankCardId}
            hideRadio={
              fundingChecklistState?.isEditable
                ? readOnlyCard
                : showExistingBankDetails
            }
          />
        ))}
      {!isEditable && !showExistingBankDetails && debitCard && (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            padding: "20px",
            backgroundColor: selectedBankCardId === 0 ? "#f4f4f4" : "white",
          }}
        >
          <Radio
            checked={selectedBankCardId === 0}
            sx={{
              padding: 0,
              color: "#00db8f",
              "&.Mui-checked": {
                color: "#00db8f",
              },
              mr: 2,
            }}
            onChange={() => setSelectedBankCardId(0)}
          />
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                fontWeight: "500",
              }}
            >
              Pay with new Debit card
            </Typography>
            {selectedBankCardId === 0 && (
              <>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        paddingLeft: "8px",
                        marginBottom: "5px",
                        color: "#838588",
                      }}
                    >
                      Name on Card
                    </Typography>
                    <TextField
                      variant="outlined"
                      value={newCard.nameOnCard}
                      onChange={(e) =>
                        handleNewCardChange("nameOnCard", e.target.value)
                      }
                      fullWidth
                      size="small"
                      placeholder="Enter Card Holder Name"
                      error={!!errors.bankCard.nameOnCard}
                      helperText={errors.bankCard.nameOnCard}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        paddingLeft: "8px",
                        marginBottom: "5px",
                        color: "#838588",
                      }}
                    >
                      Card Number
                    </Typography>
                    <TextField
                      variant="outlined"
                      value={newCard.cardNumber}
                      onChange={(e) =>
                        handleNewCardChange("cardNumber", e.target.value)
                      }
                      fullWidth
                      size="small"
                      placeholder="XXXX-XXXX-XXXX-XXXX"
                      error={!!errors.bankCard.cardNumber}
                      helperText={errors.bankCard.cardNumber}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        paddingLeft: "8px",
                        marginBottom: "5px",
                        color: "#838588",
                      }}
                    >
                      Expiration
                    </Typography>
                    <TextField
                      variant="outlined"
                      value={newCard.expiration}
                      onChange={(e) =>
                        handleNewCardChange("expiration", e.target.value)
                      }
                      fullWidth
                      size="small"
                      placeholder="MM/YY"
                      error={!!errors.bankCard.expiration}
                      helperText={errors.bankCard.expiration}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        paddingLeft: "8px",
                        marginBottom: "5px",
                        color: "#838588",
                      }}
                    >
                      Security Code
                    </Typography>
                    <TextField
                      variant="outlined"
                      value={newCard.securityCode}
                      onChange={(e) =>
                        handleNewCardChange("securityCode", e.target.value)
                      }
                      fullWidth
                      size="small"
                      placeholder="CVV"
                      error={!!errors.bankCard.securityCode}
                      helperText={errors.bankCard.securityCode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup
                      value={newCard.billingAddressSameAsPrimary}
                      onChange={(e) =>
                        setNewCard((prev: any) => ({
                          ...prev,
                          billingAddressSameAsPrimary:
                            e.target.value === "true",
                        }))
                      }
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "center",
                        mt: 2,
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        control={
                          <Radio
                            sx={{
                              color: "#00db8f",
                              padding: "0px 9px !important",
                              "&.Mui-checked": {
                                color: "#00db8f",
                                borderRadius: "50%",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              color: "#838588",
                              marginBottom: "20px",
                            }}
                          >
                            Yes, my billing address is the same as my mailing
                            address.
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value={false}
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                        control={
                          <Radio
                            sx={{
                              color: "#00db8f",
                              padding: "0px 9px !important",
                              "&.Mui-checked": {
                                color: "#00db8f",
                                borderRadius: "50%",
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "12px", color: "#838588" }}
                          >
                            No, my billing address is not the same as my mailing
                            address.
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                {!newCard.billingAddressSameAsPrimary && (
                  <Box mt={4}>
                    <Grid container spacing={2}>
                      <AddressForm
                        value={newCard?.billingAddress}
                        onChange={handleAddressChange}
                        required
                        addressRequired
                        addressLabel="Billing Address"
                        errors={errors.bankCard.billingAddress}
                      />
                    </Grid>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      )}
      <SampleCheckModal open={open} handleClose={() => setOpen(false)} />
    </Box>
  );
};

export default PaymentDetails;
