import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoanState {
  incomeSourceConfirmed: boolean;
}

const initialState: LoanState = {
  incomeSourceConfirmed: false,
};

const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    setIncomeSourceConfirmed: (state, action: PayloadAction<boolean>) => {
      state.incomeSourceConfirmed = action.payload;
    },
    resetLoanState: () => initialState,
  },
});

export const { setIncomeSourceConfirmed, resetLoanState } = loanSlice.actions;
export default loanSlice.reducer;
