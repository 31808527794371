import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/login/Login";
import MainLayout from "./components/layout/MainLayout";
import LoanApplicationForm from "./pages/MultiStepForm/LoanApplicationForm";
import SorryPage from "./pages/SorryPage/SorryPage";
import LoanPortal from "./pages/LoanPortal/LoanPortal";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import MyProfile from "./pages/MyProfile/MyProfile";
import LoanHistory from "./pages/LoanHistory/LoanHistory";
import RedeemPage from "./pages/RedeemPage/RedeemPage";
import CartPage from "./pages/RedeemPage/CartPage";
import LoanAgreementViewer from "./pages/LoanAgreement/AgreementViewer";
import PaymentHistory from "./pages/PaymentHistory/PaymentHistory";
import Payment from "./pages/Payment/Payment";
import Admin from "./pages/Admin/Admin";
import RewardHistory from "./pages/Admin/RewardHistory";
import LoanDocs from "./components/loanDocs/LoanDocs";
import NewLoan from "./pages/NewLoan/NewLoan";
import Welcome from "./pages/Welcome/Welcome";
import BankVerification from "./pages/BankVerification/BankVerification";
import BankVerificationProcess from "./pages/BankVerification/BankVerificationProcess";
import BankVerificationProcessError from "./pages/BankVerification/BankVerificationProcessError";
import BankVerificationProcessSuccess from "./pages/BankVerification/BankVerificationProcessSuccess";
import FundingChecklist from "./pages/FundingChecklist/FundingChecklist";
import SignLoanAgreement from "./pages/FundingChecklist/SignLoanAgreement";
import ApprovedSign from "./pages/FundingChecklist/ApprovedSign";
import ResetPassword from "./components/forgotPassword/ResetPassword";

type AppRouterProps = {
  isLoggedIn: boolean;
};

const AppRouter = ({ isLoggedIn }: AppRouterProps) => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/Portal" /> : <Login />}
        />
        <Route path="/login/TokenAuth" element={<Login />} />
        <Route
          path="/Login/PasswordReset"
          element={isLoggedIn ? <Navigate to="/Portal" /> : <ResetPassword />}
        />
        <Route
          path="/forgot-password"
          element={isLoggedIn ? <Navigate to="/Portal" /> : <ForgotPassword />}
        />
        <Route path="/sorry" element={<SorryPage />} />
        <Route
          path="/Portal"
          element={isLoggedIn ? <LoanPortal /> : <Navigate to="/login" />}
        />
        <Route
          path="/Portal/bankVerification"
          element={isLoggedIn ? <BankVerification /> : <Navigate to="/login" />}
        />
        <Route
          path="/Portal/bankVerificationProcess"
          element={
            isLoggedIn ? <BankVerificationProcess /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/Portal/bankVerificationProcessError/:errorCode/:requestCode"
          element={
            isLoggedIn ? (
              <BankVerificationProcessError />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/Portal/bankVerificationProcess/:requestCode"
          element={
            isLoggedIn ? (
              <BankVerificationProcessSuccess />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/Portal/fundingchecklist"
          element={isLoggedIn ? <FundingChecklist /> : <Navigate to="/login" />}
        />
        <Route
          path="/Portal/sign"
          element={
            isLoggedIn ? <SignLoanAgreement /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/Portal/complete"
          element={isLoggedIn ? <ApprovedSign /> : <Navigate to="/login" />}
        />
      </Route>

      <Route element={<MainLayout />}>
        <Route
          path="/"
          element={
            isLoggedIn ? <Navigate to="/Portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/apply/info"
          element={
            isLoggedIn ? <Navigate to="/Portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/apply/income"
          element={
            isLoggedIn ? <Navigate to="/Portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/apply/accounts"
          element={
            isLoggedIn ? <Navigate to="/Portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/apply/login"
          element={
            isLoggedIn ? <Navigate to="/Portal" /> : <LoanApplicationForm />
          }
        />
        <Route
          path="/Portal"
          element={isLoggedIn ? <LoanPortal /> : <Navigate to="/login" />}
        />
        <Route
          path="/profile"
          element={isLoggedIn ? <MyProfile /> : <Navigate to="/login" />}
        />
        <Route
          path="/loan-history"
          element={isLoggedIn ? <LoanHistory /> : <Navigate to="/login" />}
        />
        <Route
          path="/rewards"
          element={isLoggedIn ? <RedeemPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/rewards/cart"
          element={isLoggedIn ? <CartPage /> : <Navigate to="/login" />}
        />
        <Route
          path="/Portal/agreement"
          element={
            isLoggedIn ? <LoanAgreementViewer /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/apply/:loanRequestType"
          element={isLoggedIn ? <NewLoan /> : <Navigate to="/login" />}
        />
        <Route
          path="/Portal/upload"
          element={isLoggedIn ? <LoanDocs /> : <Navigate to="/login" />}
        />
        <Route path="/sorry/:reason" element={<SorryPage />} />
        <Route path="/loans/:loanId/payments" element={<PaymentHistory />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/rewards/:customerId" element={<RewardHistory />} />
        <Route
          path="/Portal/welcome"
          element={isLoggedIn ? <Welcome /> : <Navigate to="/login" />}
        />
      </Route>
      <Route
        path="*"
        element={<Navigate to={isLoggedIn ? "/Portal" : "/login"} />}
      />
    </Routes>
  );
};

export default AppRouter;
