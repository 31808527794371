import React, { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  Link,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import AmountInputField from "../../components/common/AmountField";
import AddressForm from "../../components/addressForm/AddressForm";
import {
  CheckEligibilityResponse,
  LeadsRequest,
} from "../../types/genericType";
import { createLead } from "../../services/leadsService";
import ConsentModal from "../Modals/ConsentModal";
import { checkValidations } from "../../services/loanPortalService";
import { useNavigate } from "react-router-dom";
import { handlePhoneNumberChange } from "../../utils/utils";
import BackdropLoader from "../../components/loader/BackdropLoader";

interface StepOneProps {
  nextStep: () => void;
}

const StepOne = ({ nextStep }: StepOneProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    loanAmount: "",
    firstName: "",
    lastName: "",
    email: "",
    primaryAddress: {
      addressLine1: "",
      zip: "",
      city: "",
      state: "",
      timeAtAddress: "",
    },
    phoneNumber: "",
    agreeToConsent: true,
  });

  const [errors, setErrors] = useState({
    loanAmount: "",
    firstName: "",
    lastName: "",
    email: "",
    primaryAddress: {
      addressLine1: "",
      zip: "",
      city: "",
      state: "",
      timeAtAddress: "",
    },
    phoneNumber: "",
    agreeToConsent: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const storedData = sessionStorage.getItem("LoanApplication");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        const stepOneData = {
          loanAmount: parsedData.loanAmount || "",
          firstName: parsedData.firstName || "",
          lastName: parsedData.lastName || "",
          email: parsedData.email || "",
          primaryAddress: {
            addressLine1: parsedData.primaryAddress?.addressLine1 || "",
            zip: parsedData.primaryAddress?.zip || "",
            city: parsedData.primaryAddress?.city || "",
            state: parsedData.primaryAddress?.state || "",
            timeAtAddress: parsedData.primaryAddress?.timeAtAddress || "",
          },
          phoneNumber: parsedData.phoneNumber || "",
          agreeToConsent: parsedData.agreeToConsent || true,
        };
        setFormData((prevData) => ({
          ...prevData,
          ...stepOneData,
        }));
      } catch (error) {
        console.error("Error parsing storedData from sessionStorage:", error);
      }
    }
  }, []);

  const isValidEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const isValidPhone = (phone: string) => {
    const re = /^[1-9]\d{2}-\d{3}-\d{4}$/;
    return re.test(phone);
  };

  const validate = useCallback(() => {
    const newErrors: any = {};
    const fields = {
      ...formData,
      primaryAddress: {
        ...formData.primaryAddress,
      },
    };

    Object.keys(fields).forEach((field) => {
      if (field !== "primaryAddress") {
        if (!fields[field as keyof typeof fields]) {
          newErrors[field] = "Required field";
        } else {
          if (field === "email" && !isValidEmail(formData.email)) {
            newErrors[field] = "Invalid email";
          }
          if (field === "phoneNumber" && !isValidPhone(formData.phoneNumber)) {
            newErrors[field] = "Invalid phone number";
          }
          if (field === "loanAmount") {
            parseFloat(formData.loanAmount) < 200 ||
              (parseFloat(formData.loanAmount) > 2500 &&
                (newErrors[field] =
                  "Enter an amount between $200.00 and $2,500.00"));
          }
        }
      } else {
        Object.keys(fields.primaryAddress).forEach((addressField) => {
          if (
            !fields.primaryAddress[
              addressField as keyof typeof fields.primaryAddress
            ]
          ) {
            newErrors.primaryAddress = newErrors.primaryAddress || {};
            newErrors.primaryAddress[addressField] = "Required field";
          }
        });
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const checkValidationsEligibility = async () => {
    if (validate()) {
      try {
        setLoading(true);
        const response: CheckEligibilityResponse = await checkValidations({
          validations: ["username", "address"],
          activeMilitary: true,
          address: formData.primaryAddress,
          email: formData.email,
          incomeSources: [],
        });

        if (response?.allow) {
          nextStep();
          createLeadRequest();
        } else {
          const reason =
            response.validation === "username" ? "existing-user" : "address";
          navigate(`/sorry/${reason}`, {
            state: {
              error: {
                message: response.message,
                details: response.rule,
              },
            },
          });
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const createLeadRequest = async () => {
    const leadBody: LeadsRequest = {
      source: "EasyLoansDirect",
      firstName: formData.firstName,
      lastName: formData.lastName,
      primaryAddress: formData.primaryAddress,
      email: formData.email,
      loanAmount: formData.loanAmount,
      phoneNumber: formData.phoneNumber,
    };
    const res = await createLead(leadBody);

    if (res) {
      sessionStorage.setItem(
        "Lead",
        JSON.stringify({ ...leadBody, id: res.id })
      );
    }
  };

  const handleChange = (
    field: keyof typeof formData,
    value: string | boolean
  ) => {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    if (field === "agreeToConsent") {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value as boolean,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value as string,
      }));
      if (field === "loanAmount") {
        parseFloat(value as string) < 200 || parseFloat(value as string) > 2500
          ? setErrors((prevErrors) => ({
              ...prevErrors,
              [field]: "Enter an amount between $200.00 and $2,500.00",
            }))
          : setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
      }
    }
    sessionStorage.setItem(
      "LoanApplication",
      JSON.stringify({ ...formData, [field]: value })
    );
  };

  const handleAddressChange = (change: { id: string; value: string }) => {
    setFormData((prevData) => ({
      ...prevData,
      primaryAddress: {
        ...prevData.primaryAddress,
        [change.id]: change.value,
      },
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      primaryAddress: {
        ...prevErrors.primaryAddress,
        [change.id]: "",
      },
    }));
    sessionStorage.setItem(
      "LoanApplication",
      JSON.stringify({
        ...formData,
        primaryAddress: {
          ...formData.primaryAddress,
          [change.id]: change.value,
        },
      })
    );
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Requested Loan Amount
          </Typography>
          <AmountInputField
            label=""
            amount={formData.loanAmount}
            setAmount={(value: string | boolean) =>
              handleChange("loanAmount", value)
            }
            required
            error={!!errors.loanAmount}
            helperText={errors.loanAmount}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            First Name
          </Typography>
          <TextField
            variant="outlined"
            value={formData.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter First"
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Last Name
          </Typography>
          <TextField
            variant="outlined"
            value={formData.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Last"
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Email
          </Typography>
          <TextField
            variant="outlined"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            fullWidth
            size="small"
            placeholder="email@example.com"
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <AddressForm
          value={formData.primaryAddress}
          onChange={handleAddressChange}
          required
          detailAddress
          errors={errors.primaryAddress}
          addressRequired
        />
        <Grid item xs={6}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Phone Number
          </Typography>
          <TextField
            id="phoneNumber"
            variant="outlined"
            value={formData.phoneNumber}
            onChange={(e) =>
              handlePhoneNumberChange(e, (val) =>
                handleChange("phoneNumber", val)
              )
            }
            fullWidth
            size="small"
            placeholder="XXX-XXX-XXXX"
            required
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            control={
              <Checkbox
                checked={formData.agreeToConsent}
                onChange={(e) =>
                  handleChange("agreeToConsent", e.target.checked)
                }
                name="agreeToConsent"
                color="primary"
                sx={{
                  padding: "0px 9px",
                  borderRadius: "5px",
                  color: "#00db8f",
                  "&.Mui-checked": {
                    color: "#838588",
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  color: "#838588",
                }}
              >
                I consent to the electronic communications agreement{" "}
                <Link
                  href="#"
                  underline="always"
                  sx={{
                    cursor: "pointer",
                    color: "#00db8f",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#00db8f",
                    },
                  }}
                  onClick={() => setOpen(true)}
                >
                  here
                </Link>
                .
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconButton
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={checkValidationsEligibility}
          title="SAVE & CONTINUE"
          type="contained"
        />
      </Box>
      <ConsentModal open={open} handleClose={handleClose} />
      <BackdropLoader loading={loading} message="" />
    </>
  );
};

export default StepOne;
