import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBankAccounts, fetchBankCards } from "../../redux/bankSlice";
import { RootState, AppDispatch } from "../../redux/store";
import PaymentStatusBox from "./PaymentStatusBox";
import { Box, Checkbox, Container, Grid, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import IconButton from "../../components/IconBtn/IconBtn";
import AmountInputField from "../../components/common/AmountField";
import Dropdown from "../../components/common/Dropdown";
import Constants from "../../constants/constant";
import PaymentMethod from "./PaymentMethod";
import { validateBankAccount, validateBankCard } from "../../utils/utils";
import { checkDuplicatePayment, makePayment } from "../../services/bankService";
import ErrorComp from "../../components/errorComp/ErrorComp";
import ApiSuccess from "../../components/errorComp/ApiSuccess";
import BackdropLoader from "../../components/loader/BackdropLoader";

const Payment = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { bankAccounts, bankCards, loading } = useSelector(
    (state: RootState) => state.bank
  );
  const [amount, setAmount] = React.useState("");
  const [paymentSource, setPaymentSource] = React.useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState(
    bankAccounts?.[0]
  );
  const [selectedBankCard, setSelectedBankCard] = useState(bankCards?.[0]);
  const [errors, setErrors] = useState({
    disclaimerCheck: "",
    amount: "",
    paymentSource: "",
  });
  const [disclaimerCheck, setDisclaimerCheck] = useState(false);
  const [makePrimary, setMakePrimary] = useState(true);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDuplicatePayment, setIsDuplicatePayment] = useState(false);
  const apiSuccessRef = useRef<HTMLDivElement>(null);
  const apiErrorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchBankAccounts());
    dispatch(fetchBankCards());
  }, [dispatch]);

  useEffect(() => {
    setSelectedBankAccount(bankAccounts?.[0]);
    setSelectedBankCard(bankCards?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSource]);

  useEffect(() => {
    if (apiSuccess && apiSuccessRef.current) {
      apiSuccessRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if ((apiError || isDuplicatePayment) && apiErrorRef.current) {
      apiErrorRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSuccess, apiError]);

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisclaimerCheck(e.target.checked);
    setErrors((prev) => ({
      ...prev,
      disclaimerCheck: e.target.checked ? "" : "Required Field",
    }));
  };

  const makeAchPayment = async () => {
    setIsLoading(true);
    try {
      await makePayment(amount, "Ach", null, {
        ...selectedBankAccount,
        isPrimaryBankAccount: makePrimary,
      });
      setIsLoading(false);
      setApiSuccess(true);
    } catch (err) {
      setIsLoading(false);
      setApiError("Something went wrong while making payment");
    }
  };

  const checkDuplicate = async () => {
    try {
      const data = await checkDuplicatePayment(amount, selectedBankCard);
      return data?.["resource"];
    } catch (err) {
      console.error("Error checking duplicate payment:", err);
      return false;
    }
  };

  const makeDebitCardPayment = async () => {
    setIsLoading(true);
    try {
      const isDuplicate = await checkDuplicate();

      if (isDuplicate) {
        setIsLoading(false);
        setIsDuplicatePayment(true);
        setApiError(
          "You are attempting to submit a duplicate transaction. Please adjust the payment amount or select a different payment method."
        );
        return;
      }
      await makePayment(
        amount,
        "DebitCard",
        {
          ...selectedBankCard,
          isPrimaryBankCard: makePrimary,
        },
        null
      );
      setIsLoading(false);
      setApiSuccess(true);
    } catch (err) {
      setIsLoading(false);
      setApiError("Something went wrong while making payment");
    }
  };

  const handleSubmit = () => {
    let newErrors: any = {};

    if (!amount.trim()) {
      newErrors.amount = "Required field";
    }

    if (!paymentSource) {
      newErrors.paymentSource = "Required field";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors((prevState) => ({
        ...prevState,
        ...newErrors,
      }));
      return;
    }

    if (paymentSource === "Ach") {
      const isNewAccount =
        selectedBankAccount?.id === 0 || selectedBankAccount?.id === null;

      if (isNewAccount) {
        const [isValid, accountErrors] =
          validateBankAccount(selectedBankAccount);

        if (!isValid) {
          setErrors((prevState) => ({
            ...prevState,
            bankAccount: accountErrors,
          }));
          return;
        } else {
          setErrors((prevState) => ({
            ...prevState,
            bankAccount: null,
          }));
        }
      }

      if (!disclaimerCheck) {
        setErrors((prevState) => ({
          ...prevState,
          disclaimerCheck: "Required field",
        }));
        return;
      }

      makeAchPayment();
    }

    if (paymentSource === "DebitCard") {
      const isNewCard =
        selectedBankCard?.id === 0 || selectedBankCard?.id === null;

      if (isNewCard) {
        const [isValid, cardErrors] = validateBankCard(selectedBankCard);

        if (!isValid) {
          setErrors((prevState) => ({
            ...prevState,
            bankCard: cardErrors,
          }));
          return;
        } else {
          setErrors((prevState) => ({
            ...prevState,
            bankCard: null,
          }));
        }
      }

      if (!disclaimerCheck) {
        setErrors((prevState) => ({
          ...prevState,
          disclaimerCheck: "Required field",
        }));
        return;
      }

      makeDebitCardPayment();
    }
  };

  return (
    <>
      {apiSuccess && (
        <div ref={apiSuccessRef}>
          <ApiSuccess
            message={`Posted additional payment of $${amount}`}
            onClose={() => setApiSuccess(false)}
          />
        </div>
      )}
      {(apiError || isDuplicatePayment) && (
        <div ref={apiErrorRef}>
          <ErrorComp
            message={
              isDuplicatePayment
                ? "You are attempting to submit a duplicate transaction. Please adjust the payment amount or select a different payment method."
                : "Something went wrong while making payment"
            }
            onClose={() => {
              setApiError("");
              setIsDuplicatePayment(false);
            }}
          />
        </div>
      )}
      <PaymentStatusBox />
      <Container>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            color: "#838588",
            fontSize: "18px",
            fontFamily: "AvenirNext-Medium",
            fontWeight: "bold",
            mt: 4,
          }}
        >
          Make Payment
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                paddingLeft: "8px",
                marginBottom: "5px",
                color: "#838588",
              }}
            >
              Amount
            </Typography>
            <AmountInputField
              label=""
              amount={amount}
              setAmount={(value: string) => {
                setAmount(value.replace(/[^0-9.]/g, ""));
                setErrors((prev) => ({ ...prev, amount: "" }));
              }}
              error={!!errors.amount}
              helperText={errors.amount}
              required={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              id="paymentSource"
              label="Payment Source"
              value={paymentSource}
              options={Constants.paymentOptions.map((option) => ({
                value: option.value,
                label: option.name,
              }))}
              onChange={(val) => {
                setPaymentSource(val);
                setErrors((prev) => ({ ...prev, paymentSource: "" }));
              }}
              required={true}
              error={errors.paymentSource}
            />
          </Grid>
        </Grid>
        {paymentSource && (
          <PaymentMethod
            key={paymentSource}
            bankAccount={paymentSource === "Ach"}
            debitCard={paymentSource === "DebitCard"}
            bankAccounts={bankAccounts}
            bankCards={bankCards}
            updateSelectedBankAccount={setSelectedBankAccount}
            updateSelectedBankCard={setSelectedBankCard}
            makePrimary={makePrimary}
            setMakePrimary={setMakePrimary}
            errors={errors}
          />
        )}
        <Box
          sx={{
            display: "flex",
            paddingLeft: "20px",
            alignItems: "start",
            justifyContent: "start",
            mt: 4,
          }}
        >
          <Checkbox
            id="disclaimerCheck"
            checked={disclaimerCheck}
            onChange={handleCheckBoxChange}
            required
            sx={{
              padding: 0,
              color: errors.disclaimerCheck ? "red" : "#838588",
              "&.Mui-checked": {
                color: "#838588",
              },
            }}
          />
          <Typography
            variant="body2"
            style={{
              color: "#838588",
              fontWeight: "500",
              fontSize: "12px",
              marginLeft: "16px",
            }}
          >
            I understand that if my account is set up for auto payments, a
            double payment could occur if I complete this payment. I also
            understand that if I'd like this payment to be an additional
            payment, I need to contact Lift Credit to indicate as such.
          </Typography>
        </Box>
        {errors.disclaimerCheck && (
          <Typography
            variant="caption"
            color="error"
            sx={{ marginLeft: "24px" }}
          >
            {errors.disclaimerCheck}
          </Typography>
        )}
        <Box display="flex" justifyContent="start" ml={2} mt={2}>
          <IconButton
            icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
            handleBtnClick={handleSubmit}
            title="SUBMIT PAYMENT"
            type="contained"
          />
        </Box>
      </Container>
      <BackdropLoader loading={loading} message="" />
      <BackdropLoader loading={isLoading} message="" />
    </>
  );
};

export default Payment;
