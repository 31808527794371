import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AmountInputField from "../../components/common/AmountField";
import LockIcon from "@mui/icons-material/Lock";
import IconBtn from "../../components/IconBtn/IconBtn";
import { LoanState, NewLoanApplication } from "../../types/genericType";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface Step1Props {
  loanState: LoanState;
  updateLoanApplication: (data: Partial<NewLoanApplication>) => void;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const Step1: React.FC<Step1Props> = ({
  loanState,
  updateLoanApplication,
  handleNextStep,
  handlePreviousStep,
}) => {
  const { customerLoanStatus } = useSelector((state: RootState) => state.api);
  const [amount, setAmount] = useState(loanState?.loanApplication?.loanAmount);
  const [promoCode, setPromoCode] = useState("");
  const [errors, setErrors] = useState({
    amount: "",
  });

  const [maxLoanAmount, setMaxLoanAmount] = useState(2500);
  useEffect(() => {
    const state = customerLoanStatus?.customerState || "";
    const loanRequestType = loanState.loanApplication.loanRequestType;

    if (loanRequestType === "Reloan" && (state === "UT" || state === "ID")) {
      setMaxLoanAmount(5000);
    }
  }, [customerLoanStatus, loanState.loanApplication.loanRequestType]);

  const validateAmount = (value: string): string => {
    if (!value) return "Amount is required";
    const parsedValue = parseFloat(value);
    if (
      isNaN(parsedValue) ||
      parsedValue < 200 ||
      parsedValue > maxLoanAmount
    ) {
      return `Enter an amount between $200.00 and $${maxLoanAmount.toLocaleString()}.00`;
    }
    return "";
  };

  const handleAmountChange = (value: string) => {
    setAmount(value);
    const error = validateAmount(value);
    setErrors((prevErrors) => ({ ...prevErrors, amount: error }));
  };

  const handleContinue = () => {
    const amountError = validateAmount(amount);
    if (amountError) {
      setErrors((prevErrors) => ({ ...prevErrors, amount: amountError }));
      return;
    }
    updateLoanApplication({ loanAmount: amount });
    sessionStorage.setItem("isIncomeConfirmed", JSON.stringify(false));
    handleNextStep();
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Requested Loan Amount
          </Typography>
          <AmountInputField
            label=""
            amount={amount}
            setAmount={(value: string) => handleAmountChange(value)}
            required
            error={!!errors.amount}
            helperText={errors.amount}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Promo Code (Optional)
          </Typography>
          <TextField
            variant="outlined"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            fullWidth
            size="small"
            placeholder="Enter Promo Code (Optional)"
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" mt={4}>
        <IconBtn
          icon={<LockIcon sx={{ fontSize: "18px", color: "#00db8f" }} />}
          handleBtnClick={handleContinue}
          title="CONTINUE"
          type="contained"
        />
      </Box>
    </Container>
  );
};

export default Step1;
