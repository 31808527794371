import { resetLoanState } from "../redux/loanSlice";
import { AppDispatch } from "../redux/store";

export const getUserDetails = (): string | null => localStorage.getItem("user");

export const deleteToken = (dispatch: AppDispatch) => {
  localStorage.clear();
  sessionStorage.clear();
  dispatch(resetLoanState());
};
