import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Button,
} from "@mui/material";
import Dropdown from "../common/Dropdown";
import AddressForm from "../addressForm/AddressForm";
import Constants from "../../constants/constant";
import { calculateNextPayDates, formatDateToMMDD } from "../../utils/dateUtils";
import type { Address, IncomeType, PayDay } from "../../types/genericType";
import AmountInputField from "../common/AmountField";
import HelpIncomeModal from "../../pages/Modals/HelpIncomeModal";
import {
  handlePhoneNumberChange,
  normalizeTimeRangeValue,
} from "../../utils/utils";

interface IncomeSourceFieldsProps {
  data: IncomeType | undefined;
  handleCancel: Function;
  handleSave: Function;
}

const IncomeSourceFields: React.FC<IncomeSourceFieldsProps> = React.memo(
  ({ data, handleCancel, handleSave }) => {
    const [incomeSource, setIncomeSource] = useState<IncomeType>(
      data || {
        id: null,
        monthlyIncome: 0,
        incomeType: "",
        employer: "",
        employerAddress: {
          city: "",
          state: "",
          zip: "",
        },
        timeAtEmployment: "",
        workPhoneNumber: "",
        payDay: {
          payFrequency: "",
          payDay: "",
          nextPayDate: "",
          monthlyPayDayType: "",
          firstPayWeek: "",
          firstMonthlyPayDayOfWeek: "",
          firstMonthlyPayDay: "",
          secondPayWeek: "",
          secondMonthlyPayDayOfWeek: "",
          secondMonthlyPayDay: "",
          payrollType: "",
        },
        otherIncomeDescription: "",
        dateOfHire: "",
        incomeSourceId: null,
        makePrimary: false,
      }
    );
    const [errors] = useState<{ [key: string]: string | undefined }>({});
    const [open, setOpen] = useState(false);

    const [address] = useState<Address>({
      city: data?.employerAddress?.city || "",
      state: data?.employerAddress?.state || "",
      zip: data?.employerAddress?.zip || "",
    });

    useEffect(() => {
      if (data) {
        setIncomeSource(data);
      }
    }, [data]);

    const handleFieldChange = (
      field: keyof IncomeType | keyof PayDay,
      value: any
    ) => {
      setIncomeSource((prev) => {
        const updatedPayDay: PayDay = {
          payDay: prev.payDay?.payDay || "",
          nextPayDate: prev.payDay?.nextPayDate || "",
          payrollType: prev.payDay?.payrollType || "",
          payFrequency: prev.payDay?.payFrequency || "",
          firstMonthlyPayDay: prev.payDay?.firstMonthlyPayDay,
          monthlyPayDayType: prev.payDay?.monthlyPayDayType,
          firstMonthlyPayDayOfWeek: prev.payDay?.firstMonthlyPayDayOfWeek,
          firstPayWeek: prev.payDay?.firstPayWeek,
          secondMonthlyPayDayOfWeek: prev.payDay?.secondMonthlyPayDayOfWeek,
          secondPayWeek: prev.payDay?.secondPayWeek,
          secondMonthlyPayDay: prev.payDay?.secondMonthlyPayDay,
        };

        if (field in updatedPayDay) {
          return {
            ...prev,
            payDay: {
              ...updatedPayDay,
              [field as keyof PayDay]: value,
            },
          };
        } else {
          return {
            ...prev,
            [field as keyof IncomeType]: value,
          };
        }
      });
    };

    const handleAddressChange = (change: { id: string; value: string }) => {
      setIncomeSource((prev) => ({
        ...prev,
        employerAddress: {
          ...prev.employerAddress,
          [change.id]: change.value,
        },
      }));
    };

    const renderExtraFields = (payFrequency: string) => {
      switch (payFrequency) {
        case "weekly":
          return (
            <Grid item xs={6}>
              <Dropdown
                id="payday"
                label="Payday"
                value={incomeSource?.payDay?.payDay?.toLocaleLowerCase()}
                options={Constants.dayOfWeekOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(val) => handleFieldChange("payDay", val)}
                required={true}
                error={errors.payDay}
              />
            </Grid>
          );
        case "biWeekly":
          return (
            <>
              <Grid item xs={6}>
                <Dropdown
                  id="payday"
                  label="Payday"
                  value={incomeSource?.payDay?.payDay?.toLocaleLowerCase()}
                  options={Constants.dayOfWeekOptions.map((option) => ({
                    value: option.value,
                    label: option.name,
                  }))}
                  onChange={(val) => handleFieldChange("payDay", val)}
                  required={true}
                  error={errors.payDay}
                />
              </Grid>
              {incomeSource?.payDay?.payDay && (
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      paddingLeft: "8px",
                      marginBottom: "5px",
                      color: "#838588",
                    }}
                  >
                    Next Pay Date
                  </Typography>
                  <RadioGroup
                    row
                    value={incomeSource?.payDay?.nextPayDate}
                    onChange={(e) => {
                      return handleFieldChange("nextPayDate", e.target.value);
                    }}
                    sx={{ display: "flex" }}
                  >
                    {calculateNextPayDates(incomeSource?.payDay?.payDay).map(
                      (date, idx) => {
                        const formattedDate = formatDateToMMDD(new Date(date));
                        return (
                          <FormControlLabel
                            key={idx}
                            value={date}
                            control={
                              <Radio
                                sx={{
                                  "&.MuiRadio-root": {
                                    display: "none",
                                  },
                                }}
                              />
                            }
                            label={formattedDate}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40px",
                              padding: 1,
                              cursor: "pointer",
                              marginLeft: 1,
                              textAlign: "center",
                              color: "#333",
                              "&:hover": {
                                backgroundColor: "#e0e0e0",
                              },
                              border:
                                date === incomeSource?.payDay?.nextPayDate
                                  ? "1px solid #00db8f"
                                  : "",
                              backgroundColor:
                                date === incomeSource?.payDay?.nextPayDate
                                  ? "#e0e0e0"
                                  : "white",
                              borderRadius: "4px",
                            }}
                          />
                        );
                      }
                    )}
                  </RadioGroup>
                  {errors.nextPayDate && (
                    <Typography color="error" variant="caption">
                      {errors.nextPayDate}
                    </Typography>
                  )}
                </Grid>
              )}
            </>
          );
        case "monthly":
          return (
            <>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    paddingLeft: "8px",
                    marginBottom: "5px",
                    color: "#838588",
                  }}
                >
                  I get paid on
                </Typography>
                <RadioGroup
                  row
                  value={incomeSource?.payDay?.monthlyPayDayType}
                  onChange={(e) =>
                    handleFieldChange("monthlyPayDayType", e.target.value)
                  }
                  sx={{ display: "flex" }}
                >
                  <FormControlLabel
                    value={"Day"}
                    control={
                      <Radio
                        sx={{
                          "&.MuiRadio-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Specific Day"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40px",
                      padding: 1,
                      cursor: "pointer",
                      marginLeft: 1,
                      textAlign: "center",
                      color: "#333",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                      border:
                        incomeSource?.payDay?.monthlyPayDayType === "Day"
                          ? "1px solid #00db8f"
                          : "",
                      backgroundColor:
                        incomeSource?.payDay?.monthlyPayDayType === "Day"
                          ? "#e0e0e0"
                          : "white",
                      borderRadius: "4px",
                    }}
                  />
                  <FormControlLabel
                    value={"DayOfWeek"}
                    control={
                      <Radio
                        sx={{
                          "&.MuiRadio-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Day of Week"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40px",
                      padding: 1,
                      cursor: "pointer",
                      marginLeft: 1,
                      textAlign: "center",
                      color: "#333",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                      border:
                        incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek"
                          ? "1px solid #00db8f"
                          : "",
                      backgroundColor:
                        incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek"
                          ? "#e0e0e0"
                          : "white",
                      borderRadius: "4px",
                    }}
                  />
                </RadioGroup>
                {errors.monthlyPayDayType && (
                  <Typography color="error" variant="caption">
                    {errors.monthlyPayDayType}
                  </Typography>
                )}
              </Grid>

              {incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek" ? (
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Dropdown
                        id="week"
                        label="Pay Day of Month"
                        value={incomeSource?.payDay?.firstPayWeek ?? ""}
                        options={Constants.weekOfMonthOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleFieldChange("firstPayWeek", val)
                        }
                        required={true}
                        error={errors.firstPayWeek}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Dropdown
                        id="day"
                        label=""
                        value={
                          incomeSource?.payDay?.firstMonthlyPayDayOfWeek ?? ""
                        }
                        options={Constants.dayOfWeekOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleFieldChange("firstMonthlyPayDayOfWeek", val)
                        }
                        required={true}
                        error={errors.firstMonthlyPayDayOfWeek}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <Dropdown
                        id="week"
                        label="Pay Day of Month"
                        value={incomeSource?.payDay?.firstMonthlyPayDay ?? ""}
                        options={Constants.dayOfMonthOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleFieldChange("firstMonthlyPayDay", val)
                        }
                        required={true}
                        error={errors.firstMonthlyPayDay}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingLeft: "8px",
                          marginBottom: "5px",
                          marginTop: "30px",
                          color: "#838588",
                        }}
                      >
                        of month
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          );
        case "twiceMonthly":
          return (
            <>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    paddingLeft: "8px",
                    marginBottom: "5px",
                    color: "#838588",
                  }}
                >
                  I get paid on
                </Typography>
                <RadioGroup
                  row
                  value={incomeSource?.payDay?.monthlyPayDayType}
                  onChange={(e) =>
                    handleFieldChange("monthlyPayDayType", e.target.value)
                  }
                  sx={{ display: "flex" }}
                >
                  <FormControlLabel
                    value={"Day"}
                    control={
                      <Radio
                        sx={{
                          "&.MuiRadio-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Specific Day"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40px",
                      padding: 1,
                      cursor: "pointer",
                      marginLeft: 1,
                      textAlign: "center",
                      color: "#333",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                      border:
                        incomeSource?.payDay?.monthlyPayDayType === "Day"
                          ? "1px solid #00db8f"
                          : "",
                      backgroundColor:
                        incomeSource?.payDay?.monthlyPayDayType === "Day"
                          ? "#e0e0e0"
                          : "white",
                      borderRadius: "4px",
                    }}
                  />
                  <FormControlLabel
                    value={"DayOfWeek"}
                    control={
                      <Radio
                        sx={{
                          "&.MuiRadio-root": {
                            display: "none",
                          },
                        }}
                      />
                    }
                    label="Day of Week"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40px",
                      padding: 1,
                      cursor: "pointer",
                      marginLeft: 1,
                      textAlign: "center",
                      color: "#333",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                      border:
                        incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek"
                          ? "1px solid #00db8f"
                          : "",
                      backgroundColor:
                        incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek"
                          ? "#e0e0e0"
                          : "white",
                      borderRadius: "4px",
                    }}
                  />
                </RadioGroup>
                {errors.monthlyPayDayType && (
                  <Typography color="error" variant="caption">
                    {errors.monthlyPayDayType}
                  </Typography>
                )}
              </Grid>
              {incomeSource?.payDay?.monthlyPayDayType === "DayOfWeek" ? (
                <>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Dropdown
                          id="week"
                          label="First Pay Day of Month"
                          value={incomeSource?.payDay?.firstPayWeek ?? ""}
                          options={Constants.weekOfMonthOptions.map(
                            (option) => ({
                              value: option.value,
                              label: option.name,
                            })
                          )}
                          onChange={(val) =>
                            handleFieldChange("firstPayWeek", val)
                          }
                          required={true}
                          error={errors.firstPayWeek}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Dropdown
                          id="day"
                          label=""
                          value={
                            incomeSource?.payDay?.firstMonthlyPayDayOfWeek ?? ""
                          }
                          options={Constants.dayOfWeekOptions.map((option) => ({
                            value: option.value,
                            label: option.name,
                          }))}
                          onChange={(val) =>
                            handleFieldChange("firstMonthlyPayDayOfWeek", val)
                          }
                          required={true}
                          error={errors.firstMonthlyPayDayOfWeek}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Dropdown
                          id="week"
                          label="Second Pay Day of Month"
                          value={incomeSource?.payDay?.secondPayWeek ?? ""}
                          options={Constants.weekOfMonthOptions.map(
                            (option) => ({
                              value: option.value,
                              label: option.name,
                            })
                          )}
                          onChange={(val) =>
                            handleFieldChange("secondPayWeek", val)
                          }
                          required={true}
                          error={errors.secondPayWeek}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Dropdown
                          id="day"
                          label=""
                          value={
                            incomeSource?.payDay?.secondMonthlyPayDayOfWeek ??
                            ""
                          }
                          options={Constants.dayOfWeekOptions.map((option) => ({
                            value: option.value,
                            label: option.name,
                          }))}
                          onChange={(val) =>
                            handleFieldChange("secondMonthlyPayDayOfWeek", val)
                          }
                          required={true}
                          error={errors.secondMonthlyPayDayOfWeek}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "600",
                      paddingLeft: "8px",
                      color: "#838588",
                    }}
                  >
                    I get paid on
                  </Typography>
                  <Grid container sx={{ mt: -2 }}>
                    <Grid item xs={5}>
                      <Dropdown
                        id="week"
                        label=""
                        value={incomeSource?.payDay?.firstMonthlyPayDay ?? ""}
                        options={Constants.dayOfMonthOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleFieldChange("firstMonthlyPayDay", val)
                        }
                        required={true}
                        error={errors.firstMonthlyPayDay}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingLeft: "18px",
                          marginBottom: "5px",
                          marginTop: "30px",
                          color: "#838588",
                        }}
                      >
                        and
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Dropdown
                        id="week"
                        label=""
                        value={incomeSource?.payDay?.secondMonthlyPayDay ?? ""}
                        options={Constants.dayOfMonthOptions.map((option) => ({
                          value: option.value,
                          label: option.name,
                        }))}
                        onChange={(val) =>
                          handleFieldChange("secondMonthlyPayDay", val)
                        }
                        required={true}
                        error={errors.secondMonthlyPayDay}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          );
        default:
          return null;
      }
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              paddingLeft: "8px",
              marginBottom: "5px",
              color: "#838588",
            }}
          >
            Monthly Net Income
          </Typography>
          <AmountInputField
            label=""
            amount={incomeSource.monthlyIncome.toString()}
            setAmount={(value: string) =>
              handleFieldChange("monthlyIncome", value)
            }
            error={!!errors.monthlyIncome}
            helperText={errors.monthlyIncome}
            showHelperText={() => {
              setOpen(true);
            }}
            placeholder=" Enter Monthly Net Income"
          />
        </Grid>
        <Grid item xs={12}>
          <Dropdown
            id="incomeSource"
            label="Income Source"
            value={incomeSource.incomeType.toLocaleLowerCase()}
            options={Constants.incomeTypeOptions.map((option) => ({
              value: option.value,
              label: option.name,
            }))}
            onChange={(val) => handleFieldChange("incomeType", val)}
            required={true}
            error={errors.incomeType}
          />
        </Grid>
        {incomeSource.incomeType === "Employment" && (
          <>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Employer Name
              </Typography>
              <TextField
                variant="outlined"
                value={incomeSource.employer}
                onChange={(e) => handleFieldChange("employer", e.target.value)}
                fullWidth
                size="small"
                placeholder="Enter Employer Name"
                error={!!errors.employer}
                helperText={errors.employer}
              />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    paddingLeft: "8px",
                    color: "#838588",
                  }}
                >
                  Employer Address
                </Typography>
              </Grid>
              <AddressForm
                value={address}
                onChange={handleAddressChange}
                required
              />
            </Grid>
          </>
        )}
        {(incomeSource.incomeType === "Employment" ||
          incomeSource.incomeType === "SelfEmployed") && (
          <>
            <Grid item xs={6}>
              <Dropdown
                id="timeAtAddress"
                label="Time at Employer"
                value={normalizeTimeRangeValue(
                  incomeSource.timeAtEmployment || ""
                )}
                options={Constants.timeRangeOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                }))}
                onChange={(value: string) =>
                  handleFieldChange("timeAtEmployment", value)
                }
                error={errors?.timeAtEmployment}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Employer Phone
              </Typography>
              <TextField
                id="workPhoneNumber"
                variant="outlined"
                value={incomeSource.workPhoneNumber || ""}
                onChange={(e) =>
                  handlePhoneNumberChange(e, (val) =>
                    handleFieldChange("workPhoneNumber", val)
                  )
                }
                fullWidth
                size="small"
                placeholder="XXX-XXX-XXXX"
                required={false}
                error={!!errors?.workPhoneNumber}
                helperText={errors?.workPhoneNumber}
              />
            </Grid>
          </>
        )}
        {incomeSource.incomeType === "Other" && (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                paddingLeft: "8px",
                marginBottom: "5px",
                color: "#838588",
              }}
            >
              Description
            </Typography>
            <TextField
              variant="outlined"
              value={incomeSource.otherIncomeDescription}
              onChange={(e) =>
                handleFieldChange("otherIncomeDescription", e.target.value)
              }
              fullWidth
              size="small"
              placeholder="Income Source Description"
              error={!!errors?.otherIncomeDescription}
            />
            {errors?.otherIncomeDescription && (
              <Typography color="error" variant="caption">
                {errors?.otherIncomeDescription}
              </Typography>
            )}
          </Grid>
        )}
        <Grid item xs={6}>
          <Dropdown
            id="payrollType"
            label="Payroll Information"
            value={
              (incomeSource.payDay?.payrollType || "").charAt(0).toLowerCase() +
              (incomeSource.payDay?.payrollType || "").slice(1)
            }
            options={Constants.payrollTypeOptions.map((option) => ({
              value: option.value,
              label: option.name,
            }))}
            onChange={(val) => handleFieldChange("payrollType", val)}
            required={true}
            error={errors.payrollType}
          />
        </Grid>
        <Grid item xs={6}>
          <Dropdown
            id="payFrequency"
            label="Pay Period"
            value={
              (incomeSource.payDay?.payFrequency || "")
                .charAt(0)
                .toLowerCase() +
              (incomeSource.payDay?.payFrequency || "").slice(1)
            }
            options={Constants.payFrequencyOptions.map((option) => ({
              value: option.value,
              label: option.name,
            }))}
            onChange={(val) => handleFieldChange("payFrequency", val)}
            required={true}
            error={errors.payFrequency}
          />
        </Grid>
        {renderExtraFields(
          (incomeSource.payDay?.payFrequency || "").charAt(0).toLowerCase() +
            (incomeSource.payDay?.payFrequency || "").slice(1)
        )}{" "}
        <HelpIncomeModal open={open} handleClose={() => setOpen(false)} />
        <Grid item xs={12}>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="text"
              sx={{
                color: "#838588",
                textTransform: "none",
                marginRight: 2,
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#00db8f",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#00db8f",
                },
              }}
              onClick={() => handleSave(incomeSource)}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }
);

export default IncomeSourceFields;
