import { Container, Box, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  getPendingLoanApplicationStep,
  getPendingLoanDetails,
} from "../../services/loanPortalService";
import { formatDateMinusOneDay, formatDateTime } from "../../utils/dateUtils";
import GenericButton from "../../components/common/GenericButton";

type Props = {
  customerLoanStatus: any;
};

const PendingLoan = (props: Props) => {
  const { customerLoanStatus } = props;
  const [loanData, setLoanData] = useState<any | null>(null);
  const [pendingLoanApplicationStep, setPendingLoanApplicationStep] = useState<
    any | null
  >(null);
  const navigate = useNavigate();

  const fetchPendingLoan = async () => {
    try {
      const response = await getPendingLoanDetails();
      setLoanData(response);
      if (response) {
        const res = await getPendingLoanApplicationStep(response.id);
        if (res) {
          setPendingLoanApplicationStep(res);
        }
      }
    } catch (error) {
      console.error("Error fetching pending loan data:", error);
    }
  };

  useEffect(() => {
    fetchPendingLoan();
  }, []);

  const handleContinuePendingLoanClick = () => {
    const stepName = pendingLoanApplicationStep?.stepName;
    switch (stepName) {
      case "SignLoanAgreement":
        navigate("/Portal/sign");
        break;
      case "BankVerification":
        navigate("/Portal/bankVerificationProcess");
        break;
      case "FundingChecklist":
        navigate("/Portal/fundingChecklist");
        break;
      case "Completed":
        navigate("/Portal/loan");
        break;
      default:
        navigate("/Portal/upload");
    }
  };

  const getApprovalStatus = () => {
    let approvalStatus = customerLoanStatus?.pending?.approvalStatus;
    if (pendingLoanApplicationStep) {
      switch (pendingLoanApplicationStep.stepName) {
        case "SignLoanAgreement":
          approvalStatus = "Pending Final Signature";
          break;
        case "BankVerification":
          approvalStatus = "Pending Income Verification";
          break;
        case "FundingChecklist":
          approvalStatus = "Pending Payment Information";
          break;
        default:
          break;
      }
    }
    return approvalStatus;
  };

  const approvalStatus = getApprovalStatus();

  const pendingAmount =
    pendingLoanApplicationStep &&
    pendingLoanApplicationStep?.requestedLoanAmount > 0;

  return (
    <Container maxWidth="md" sx={{ margin: "4rem 0" }}>
      <Box sx={{ textAlign: "left", marginBottom: "2rem" }}>
        <Typography variant="h5" sx={{ color: "#00db8f", fontWeight: "500" }}>
          Application Status: {approvalStatus}
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{ padding: 0 }}>
        <Grid item xs={12} sm={6} sx={{ padding: "2rem 0" }}>
          <Box
            sx={{
              padding: "0 2rem",
              borderRight: { xs: "none", sm: "1px solid #e0e0e0" },
              borderBottom: { xs: "1px solid #e0e0e0", sm: "none" },
            }}
          >
            {(pendingAmount || customerLoanStatus?.pending?.amount) && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    fontWeight: "500",
                    fontSize: "14px",
                    fontFamily: "AvenirNext-Medium",
                  }}
                >
                  Requested Loan Amount
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: pendingAmount ? "#838588" : "#5a5a5b",
                    fontWeight: "500",
                    fontSize: pendingAmount ? "14px" : "24px",
                    fontFamily: "AvenirNext-Medium",
                    mt: pendingAmount ? 0 : 2,
                  }}
                >
                  {`$${parseFloat(
                    pendingAmount
                      ? pendingLoanApplicationStep?.requestedLoanAmount
                      : customerLoanStatus?.pending?.amount
                  ).toFixed(2)}`}
                </Typography>
              </>
            )}
            {pendingLoanApplicationStep?.approvedLoanAmount > 0 && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#838588",
                    fontWeight: "500",
                    fontSize: "14px",
                    fontFamily: "AvenirNext-Medium",
                    marginTop: "1rem",
                  }}
                >
                  Max Approval Amount:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#838588",
                    fontWeight: "500",
                    fontSize: "14px",
                    fontFamily: "AvenirNext-Medium",
                  }}
                >
                  {`$${pendingLoanApplicationStep.approvedLoanAmount.toFixed(
                    2
                  )}`}
                </Typography>
              </>
            )}
            {loanData?.appliedDate && (
              <Typography
                variant="body2"
                sx={{
                  color: "#5a5a5b",
                  fontWeight: "600",
                  fontSize: "16px",
                  fontFamily: "AvenirNext-Regular",
                  marginBottom: "2rem",
                  mt: 2,
                }}
              >
                Application Date :{" "}
                {formatDateMinusOneDay(loanData?.appliedDate)}
              </Typography>
            )}
            {loanData?.id && (
              <Typography
                variant="body2"
                sx={{
                  color: "#5a5a5b",
                  fontWeight: "600",
                  fontSize: "16px",
                  fontFamily: "AvenirNext-Regular",
                  marginBottom: "1rem",
                }}
              >
                Loan #: {loanData?.id}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ padding: "0 2rem" }}>
          <Box sx={{ paddingLeft: 2 }}>
            {pendingLoanApplicationStep?.stepName === "BankVerification" && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#5a5a5b",
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  The next step of your approval process is to verify your
                  income. Click the button below to proceed to the next step of
                  your application.
                </Typography>
                <GenericButton
                  label="Verify Income"
                  onClick={handleContinuePendingLoanClick}
                />
              </>
            )}
            {pendingLoanApplicationStep?.stepName === "FundingChecklist" && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#5a5a5b",
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  The next step of the approval process is to verify how you
                  would like to receive funds and make payments.
                </Typography>
                <GenericButton
                  label="Verify Payment Information"
                  onClick={handleContinuePendingLoanClick}
                />
              </>
            )}
            {pendingLoanApplicationStep?.stepName === "SignLoanAgreement" && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#5a5a5b",
                    marginBottom: "1rem",
                    fontWeight: "500",
                    fontSize: "16px",
                    fontFamily: "AvenirNext-Regular",
                  }}
                >
                  The last step of the approval process is to e-sign your loan
                  agreement. Click the button below to go to the loan agreement
                  screen.
                </Typography>
                <GenericButton
                  label="Sign Loan Agreement"
                  onClick={handleContinuePendingLoanClick}
                />
              </>
            )}
            {pendingLoanApplicationStep?.stepName === "Completed" &&
              approvalStatus === "Pending Final Approval" && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#5a5a5b",
                      marginBottom: "1rem",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Congratulations, your agreement has been signed and is
                    pending final approval! Our team will review your account
                    and once approved you will receive a final confirmation
                    text.
                    <br />
                    <br /> If you have any questions, reach out to us at
                    801-477-1222 or email us at support@liftcredit.com.
                  </Typography>
                </>
              )}

            {!pendingLoanApplicationStep?.stepName &&
              (approvalStatus === "Pending" ||
                approvalStatus === "Pending Final Approval") && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#5a5a5b",
                      marginBottom: "1rem",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Your application is being reviewed and is pending final
                    approval. A loan processor will be reaching out shortly.
                    <br />
                    <br /> If you need to re-upload or upload additional
                    documents for your file please use the Upload Documents
                    button below.
                  </Typography>
                  <GenericButton
                    label="Upload Documents"
                    onClick={handleContinuePendingLoanClick}
                  />
                </>
              )}
            {pendingLoanApplicationStep?.stepName === "Review" &&
              (approvalStatus === "Pending" ||
                approvalStatus === "Pending Final Approval") && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#5a5a5b",
                      marginBottom: "1rem",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Your application is being reviewed and is pending final
                    approval. A loan processor will be reaching out shortly.
                    <br />
                    <br /> If you need to re-upload or upload additional
                    documents for your file please use the Upload Documents
                    button below.
                  </Typography>
                  <GenericButton
                    label="Upload Documents"
                    onClick={handleContinuePendingLoanClick}
                  />
                </>
              )}
            {!pendingLoanApplicationStep && approvalStatus === "Approved" && (
              <>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#5a5a5b",
                    marginBottom: "1rem",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  Your loan has been approved and is ready to be signed.
                  <br />
                  Please review the loan agreement terms and sign to complete
                  the application by clicking the button below.
                </Typography>
                <GenericButton
                  label="Sign Loan Agreement"
                  onClick={() => {
                    navigate("/Portal/sign");
                  }}
                />
              </>
            )}
            {pendingLoanApplicationStep &&
              pendingLoanApplicationStep?.dateTimeApplicationEnd &&
              pendingLoanApplicationStep.stepName !== "Completed" && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "##5a5a5b",
                      marginBottom: "1rem",
                      fontWeight: "500",
                      fontSize: "16px",
                      fontFamily: "AvenirNext-Medium",
                      mt: 1,
                    }}
                  >
                    Application is valid until : &nbsp;{" "}
                    {formatDateTime(
                      pendingLoanApplicationStep.dateTimeApplicationEnd
                    )}
                  </Typography>
                </>
              )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PendingLoan;
