import axios from "axios";
import { deleteToken, getUserDetails } from "./auth";
import { apiEndPoints } from "../constants/apiEndpoints";
import globalRouter from "./globalRouter";
import { AppDispatch } from "../redux/store";
import { useDispatch } from "react-redux";

const BASE_URL = window.location.origin;
const DEFAULT_TIMEOUT = 110000;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.url = `${BASE_URL}${config.url}`;

    config.timeout = config.timeout || DEFAULT_TIMEOUT;

    const user = getUserDetails();
    const userDetails = user ? JSON.parse(user) : null;
    const token = userDetails?.authHeader;
    if (token) {
      config.headers["X-Authorization"] = token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      originalRequest.url !== `${BASE_URL}${apiEndPoints.login}`
    ) {
      const dispatch = useDispatch<AppDispatch>();
      deleteToken(dispatch);
      typeof globalRouter?.navigate === "function" &&
        globalRouter.navigate("/login");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
