import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { formatAmount } from "../../utils/utils";
import { formatDateMinusOneDay } from "../../utils/dateUtils";

const PaymentStatusBox = () => {
  const { currentLoanStatus } = useSelector((state: RootState) => state.api);
  const nextPaymentDate =
    currentLoanStatus?.nextPaymentDueDate &&
    currentLoanStatus?.nextPaymentDueDate !== "0001-01-01T00:00:00"
      ? currentLoanStatus.nextPaymentDueDate
      : null;
  const pastDueDays = currentLoanStatus?.pastDueDays ?? 0;

  return (
    <Container>
      <Typography
        sx={{ fontSize: "24px", color: "#00db8f", mb: 2, fontWeight: "500" }}
      >
        Next Payment Amount
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            borderRight: "1px solid #e6e7e8",
            textAlign: "left",
            padding: 2,
          }}
        >
          <Typography
            sx={{ color: "#5a5a5b", fontSize: { xs: "16px", sm: "24px" } }}
          >
            {formatAmount(currentLoanStatus?.remainingLoanBalance)}
          </Typography>
          <Typography
            sx={{
              color: "#6d6e71",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: "bold",
            }}
          >
            Loan Balance
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderRight: "1px solid #e6e7e8",
            textAlign: "left",
            padding: 2,
          }}
        >
          <Typography
            sx={{ color: "#5a5a5b", fontSize: { xs: "16px", sm: "24px" } }}
          >
            {nextPaymentDate ? formatDateMinusOneDay(nextPaymentDate) : "N/A"}
          </Typography>
          <Typography
            sx={{
              color: "#6d6e71",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: "bold",
            }}
          >
            {nextPaymentDate &&
              pastDueDays > 0 &&
              `(${pastDueDays} day${pastDueDays > 1 ? "s" : ""} past due)`}
            <br /> Due Date
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "left", padding: 2 }}>
          <Typography
            sx={{ color: "#5a5a5b", fontSize: { xs: "16px", sm: "24px" } }}
          >
            {formatAmount(currentLoanStatus?.nextPaymentAmount)}
          </Typography>
          <Typography
            sx={{
              color: "#6d6e71",
              fontSize: { xs: "12px", sm: "14px" },
              fontWeight: "bold",
            }}
          >
            Payment Amount
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentStatusBox;
