import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../utils/utils";
import { formatDateMinusOneDay } from "../../utils/dateUtils";
import GenericButton from "../../components/common/GenericButton";

type Props = {
  currentLoanStatus: any;
  rewardsDetails: any;
};

const ActiveLoan = (props: Props) => {
  const { currentLoanStatus, rewardsDetails } = props;
  const navigate = useNavigate();

  const nextPaymentDate =
    currentLoanStatus?.nextPaymentDueDate &&
    currentLoanStatus?.nextPaymentDueDate !== "0001-01-01T00:00:00"
      ? currentLoanStatus.nextPaymentDueDate
      : null;

  const pastDueDays = currentLoanStatus?.pastDueDays ?? 0;
  const displayMessage = currentLoanStatus?.displayMessage;

  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
      <Box sx={{ textAlign: "left", marginBottom: "2rem" }}>
        <Typography variant="h5" sx={{ color: "#00db8f", fontWeight: "bold" }}>
          Loan Status: {currentLoanStatus?.status}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ padding: "2rem" }}>
            <Typography
              variant="h6"
              sx={{ color: "#555658", fontSize: "22px", fontWeight: "400" }}
            >
              {formatAmount(currentLoanStatus?.remainingLoanBalance)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Remaining Loan Balance
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#555658", fontSize: "22px", fontWeight: "400" }}
            >
              {currentLoanStatus?.apr}%
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Interest Rate
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#555658", fontSize: "22px", fontWeight: "400" }}
            >
              {nextPaymentDate ? formatDateMinusOneDay(nextPaymentDate) : "N/A"}
              {nextPaymentDate && pastDueDays > 0 && (
                <span
                  style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    fontFamily: "AvenirNext-DemiBold",
                    fontWeight: "700",
                    paddingLeft: "8px",
                  }}
                >
                  &nbsp;({pastDueDays} day{pastDueDays > 1 ? "s" : ""} past due)
                </span>
              )}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "2rem",
                fontWeight: "bold",
              }}
            >
              Due Date
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#555658",
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: "12px",
              }}
            >
              {displayMessage
                ? `*${displayMessage}`
                : `*Next Payment Amount: ${formatAmount(
                    currentLoanStatus?.nextPaymentAmount
                  )}`}
            </Typography>
            <GenericButton
              label="LOAN AGREEMENT"
              onClick={() => navigate("/Portal/agreement")}
            />
            <Typography
              variant="caption"
              display="block"
              sx={{
                color: "#838588",
                marginTop: "0.5rem",
                fontStyle: "italic",
              }}
            >
              Loan Number: {currentLoanStatus?.id}
            </Typography>
          </Box>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginTop: "2rem", marginBottom: "2rem" }}
        />
        <Grid item xs={12} sm={5}>
          <Box sx={{ padding: "2rem" }}>
            <Typography variant="h6" sx={{ color: "#333" }}>
              {rewardsDetails?.pointAmount}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              *Total Reward Points
            </Typography>
            <Typography variant="h6" sx={{ color: "#333" }}>
              {rewardsDetails?.amount}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Total Reward Value
            </Typography>
            <Typography variant="h6" sx={{ color: "#333" }}>
              {rewardsDetails?.qualifyingPayments}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1.7rem",
                fontWeight: "bold",
              }}
            >
              On-Time Payments
            </Typography>
            <GenericButton
              label="REDEEM POINTS"
              onClick={() => navigate("/rewards")}
            />
            <Typography
              variant="caption"
              display="block"
              sx={{
                color: "#838588",
                marginTop: "0.5rem",
                fontStyle: "italic",
              }}
            >
              *2500 points minimum to redeem
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActiveLoan;
