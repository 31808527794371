import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../utils/utils";
import GenericButton from "../../components/common/GenericButton";
import { getExcludedRewardStates } from "../../services/rewardsService";

type Props = {
  currentLoanStatus: any;
  rewardsDetails: any;
  customerLoanStatus: any;
};

const PaidLoan = (props: Props) => {
  const { currentLoanStatus, rewardsDetails, customerLoanStatus } = props;
  const [isExcludedState, setIsExcludedState] = useState(false);
  const navigate = useNavigate();

  const fetchExcludedStates = async () => {
    try {
      const excludedStates = await getExcludedRewardStates();
      if (excludedStates?.includes(customerLoanStatus?.customerState)) {
        setIsExcludedState(true);
      }
    } catch (error) {
      console.error("Error fetching excluded states:", error);
    }
  };

  useEffect(() => {
    fetchExcludedStates();
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
      <Box sx={{ textAlign: "left", marginBottom: "2rem" }}>
        <Typography variant="h5" sx={{ color: "#00db8f", fontWeight: "500" }}>
          Loan Status: {currentLoanStatus?.status}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              padding: "2rem",
              borderRight: { xs: "none", sm: "1px solid #e0e0e0" },
              borderBottom: { xs: "1px solid #e0e0e0", sm: "none" },
            }}
          >
            <Typography variant="h6" sx={{ color: "#333" }}>
              {formatAmount(currentLoanStatus?.remainingLoanBalance)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              Remaining Loan Balance
            </Typography>
            <Typography variant="h6" sx={{ color: "#333" }}>
              {currentLoanStatus?.apr}%
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#838588",
                marginBottom: "6rem",
                fontWeight: "bold",
              }}
            >
              Interest Rate
            </Typography>
            <GenericButton
              label="LOAN AGREEMENT"
              onClick={() => navigate("/Portal/agreement")}
            />
            <Typography
              variant="caption"
              display="block"
              sx={{
                color: "#838588",
                marginTop: "0.5rem",
                fontStyle: "italic",
              }}
            >
              Loan Number: {currentLoanStatus?.id}
            </Typography>
          </Box>
        </Grid>
        {!isExcludedState && (
          <Grid item xs={12} sm={6}>
            <Box sx={{ padding: "2rem" }}>
              <Typography variant="h6" sx={{ color: "#333" }}>
                {rewardsDetails?.pointAmount}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#838588",
                  marginBottom: "1rem",
                  fontWeight: "bold",
                }}
              >
                *Total Reward Points
              </Typography>
              <Typography variant="h6" sx={{ color: "#333" }}>
                {rewardsDetails?.amount}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#838588",
                  marginBottom: "1rem",
                  fontWeight: "bold",
                }}
              >
                Total Reward Value
              </Typography>
              <Typography variant="h6" sx={{ color: "#333" }}>
                {rewardsDetails?.qualifyingPayments}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#838588",
                  marginBottom: "1.7rem",
                  fontWeight: "bold",
                }}
              >
                On-Time Payments
              </Typography>
              <GenericButton
                label="REDEEM POINTS"
                onClick={() => navigate("/rewards")}
              />
              <Typography
                variant="caption"
                display="block"
                sx={{
                  color: "#838588",
                  marginTop: "0.5rem",
                  fontStyle: "italic",
                }}
              >
                *2500 points minimum to redeem
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PaidLoan;
