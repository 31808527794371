import Constants from "../constants/constant";
import {
  BankAccountType,
  BankCardType,
  IncomeType,
  PayDay,
} from "../types/genericType";
import { formatDateMinusOneDay } from "./dateUtils";

export const normalizeTimeRangeValue = (value: string) => {
  if (value === "ZeroToTwoMonths" || value === "0") {
    return "0";
  } else if (value === "ThreeToSixMonths" || value === "3") {
    return "3";
  } else if (value === "SevenToElevenMonths" || value === "7") {
    return "7";
  } else if (value === "TwelveMonthsOrMore" || value === "12") {
    return "12";
  }
  return value;
};

export const generateFrequency = (payDay: PayDay) => {
  const {
    payFrequency,
    payDay: weeklyPayDay,
    nextPayDate,
    monthlyPayDayType,
    firstPayWeek,
    firstMonthlyPayDayOfWeek,
    firstMonthlyPayDay,
    secondPayWeek,
    secondMonthlyPayDayOfWeek,
    secondMonthlyPayDay,
  } = payDay;

  const dayOfMonthOptions = Constants.dayOfMonthOptions;

  switch (payFrequency?.toLocaleLowerCase()) {
    case "weekly":
      return `Each ${weeklyPayDay}`;

    case "biweekly":
      return `Each other ${weeklyPayDay} (e.g. ${formatDateMinusOneDay(
        nextPayDate
      )})`;

    case "monthly":
      if (monthlyPayDayType === "DayOfWeek") {
        return `The ${firstPayWeek} ${firstMonthlyPayDayOfWeek} of each month`;
      }
      return `The ${
        dayOfMonthOptions.find(
          (option) => option.value === String(firstMonthlyPayDay)
        )?.name
      } of each month`;

    case "twicemonthly":
      if (monthlyPayDayType === "DayOfWeek") {
        return `The ${firstPayWeek} ${firstMonthlyPayDayOfWeek} and ${secondPayWeek} ${secondMonthlyPayDayOfWeek} of each month`;
      }
      return `The ${
        dayOfMonthOptions.find(
          (option) => option.value === String(firstMonthlyPayDay)
        )?.name
      } and ${
        dayOfMonthOptions.find(
          (option) => option.value === String(secondMonthlyPayDay)
        )?.name
      } of each month`;

    default:
      return payFrequency;
  }
};

export const removeBlankFields = (
  obj: any[] | { [s: string]: unknown } | ArrayLike<unknown> | {}
): any => {
  if (Array.isArray(obj)) {
    return obj
      .map(removeBlankFields)
      .filter((item) => Object.keys(item).length > 0);
  }

  if (obj && typeof obj === "object") {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value || value === 0 || value === false) {
        acc[key] = removeBlankFields(value);
      }
      return acc;
    }, {} as { [s: string]: unknown });
  }

  return obj;
};

export const isValid = (errors: any) => {
  return Object.values(errors).every((error) => {
    if (Array.isArray(error)) {
      return error.every((srcErrors) =>
        Object.values(srcErrors).every((val) => {
          if (typeof val === "object" && val !== null) {
            return Object.values(val).every((innerVal) => !innerVal);
          }
          return !val;
        })
      );
    }
    return !error;
  });
};

export const createLoanApplicationRequestBody = (input: any) => {
  return {
    verification: input.verification,
    firstName: input.firstName,
    lastName: input.lastName,
    address: input.primaryAddress,
    email: input.email,
    monthlyIncome: input.monthlyIncome,
    dateOfBirth: input.dateOfBirth,
    phoneNumber: input.phoneNumber,
    consentToMarketing: true,
    bankVerificationRequestResult: "No",
    socialSecurityNumber: input.ssn,
    advertisingMethod: input.advertisingMethod.toString(),
    advertisingMethodText: null,
    licenseNumber: input.licenseNumber,
    licenseState: input.licenseState,
    timeAtAddress: input.primaryAddress.timeAtAddress,
    optInToMarketingMessage: input.optInToMarketingMessage,
    optInToReceiveTextMessage: input.optInToReceiveTextMessage,
    incomeSources: removeBlankFields(input.incomeSources).map(
      (source: IncomeType, i: any) => {
        return {
          ...source,
          monthlyIncome: i === 0 ? input.monthlyIncome : 1,
        };
      }
    ),
    bankAccounts: input.bankAccounts.map(
      (bankAccount: BankAccountType, index: number) => {
        return {
          ...bankAccount,
          id: index,
        };
      }
    ),
    bankCard: input.bankCard,
    loanRequest: input.loanRequest,
  };
};

export const formatPhoneNumber = (number: string) => {
  const digits = number.replace(/\D/g, "");
  if (digits.length <= 3) return digits;
  if (digits.length <= 6) return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};

export const handlePhoneNumberChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  onChange: (value: string) => void
) => {
  const newPhoneNumber = e.target.value.slice(0, 12);
  const formattedPhoneNumber = formatPhoneNumber(newPhoneNumber);
  onChange(formattedPhoneNumber);
};

export const validateBankAccount = (
  bankAccount: BankAccountType
): [boolean, { [key in keyof BankAccountType]: string }] => {
  let hasError = false;
  const newErrors: { [key in keyof BankAccountType]: string } = {
    bankRoutingNumber: "",
    bankAccountNumber: "",
    bankName: "",
    bankAccountType: "",
  };

  Object.entries(bankAccount).forEach(([field, value]) => {
    let errorMessage = "";

    switch (field) {
      case "bankRoutingNumber":
        if (typeof value === "string" && value.trim() === "") {
          errorMessage = "Required field";
          hasError = true;
        } else if (typeof value === "string" && !/^\d{9}$/.test(value)) {
          errorMessage = "Routing number must be 9 digits.";
          hasError = true;
        }
        break;
      case "bankAccountNumber":
        if (typeof value === "string" && value.trim() === "") {
          errorMessage = "Required field";
          hasError = true;
        } else if (typeof value === "string" && !/^\d{6,16}$/.test(value)) {
          errorMessage = "Account number must be between 6 to 16 digits.";
          hasError = true;
        }
        break;
      case "bankName":
        if (typeof value === "string" && value.trim() === "") {
          errorMessage = "Required field";
          hasError = true;
        }
        break;
      case "bankAccountType":
        if (typeof value === "string" && value.trim() === "") {
          errorMessage = "Required field";
          hasError = true;
        }
        break;
      default:
        break;
    }

    newErrors[field as keyof BankAccountType] = errorMessage;
  });

  return [!hasError, newErrors];
};
export const validateBankCard = (
  bankCard: BankCardType
): [
  boolean,
  {
    nameOnCard: string;
    cardNumber: string;
    billingAddress: {
      addressLine1: string;
      city: string;
      state: string;
      zip: string;
    };
    securityCode: string;
    expiration: string;
  }
] => {
  let hasError = false;

  const newErrors = {
    nameOnCard: "",
    cardNumber: "",
    billingAddress: {
      addressLine1: "",
      city: "",
      state: "",
      zip: "",
    },
    securityCode: "",
    expiration: "",
  };

  if (!bankCard.nameOnCard || bankCard.nameOnCard.trim() === "") {
    newErrors.nameOnCard = "Required field";
    hasError = true;
  }

  if (!bankCard.cardNumber || bankCard.cardNumber.trim() === "") {
    newErrors.cardNumber = "Required field";
    hasError = true;
  } else if (!/^\d{16}$/.test(bankCard.cardNumber.replace(/-/g, ""))) {
    newErrors.cardNumber = "Card number must be 16 digits.";
    hasError = true;
  }

  if (!bankCard.expiration || bankCard.expiration.trim() === "") {
    newErrors.expiration = "Required field";
    hasError = true;
  } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(bankCard.expiration)) {
    newErrors.expiration = "Invalid expiration date.";
    hasError = true;
  }

  if (!bankCard.securityCode || bankCard.securityCode.trim() === "") {
    newErrors.securityCode = "Required field";
    hasError = true;
  } else if (!/^\d{4,6}$/.test(bankCard.securityCode)) {
    newErrors.securityCode = "Security code must be between 4 to 6 digits.";
    hasError = true;
  }

  if (!bankCard.billingAddressSameAsPrimary) {
    if (
      bankCard.billingAddress === null ||
      bankCard.billingAddress === undefined
    ) {
      newErrors.billingAddress.addressLine1 = "Required field";
      newErrors.billingAddress.city = "Required field";
      newErrors.billingAddress.state = "Required field";
      newErrors.billingAddress.zip = "Required field";
      hasError = true;
    } else {
      const { addressLine1, city, state, zip } = bankCard.billingAddress;

      if (!addressLine1 || addressLine1.trim() === "") {
        newErrors.billingAddress.addressLine1 = "Required field";
        hasError = true;
      }
      if (!city || city.trim() === "") {
        newErrors.billingAddress.city = "Required field";
        hasError = true;
      }
      if (!state || state.trim() === "") {
        newErrors.billingAddress.state = "Required field";
        hasError = true;
      }
      if (!zip || zip.trim() === "") {
        newErrors.billingAddress.zip = "Required field";
        hasError = true;
      } else if (!/^\d{5}(-\d{4})?$/.test(zip)) {
        newErrors.billingAddress.zip = "Invalid ZIP code.";
        hasError = true;
      }
    }
  }

  return [!hasError, newErrors];
};

export const formatAmount = (amount: number | string): string => {
  const numericAmount = parseFloat(amount?.toString());

  if (isNaN(numericAmount) || numericAmount <= 0) {
    return "$0.00";
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(numericAmount);
};
